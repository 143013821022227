import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import TicketDetails from "./TicketDetails";
import SalesTicketList from "./SalesTicketList";

// import EventQueries from "./EventQueries";
import EventScreen from "./EventScreen";
import SalesEventList from "./SalesEventList";
// import Sales from "./Sales";
import { useRoute } from "@react-navigation/native";
import TicketEvent from "./TicketEvent";

const {width, height} = Dimensions.get('screen');

const mainColour = '#d40404';

const HeaderList = [
     
    {
        id: 0,
        item: 'Manage'
    },
    {
        id: 1,
        item: 'Sales'
    }
     
]

const HeadingLis = HeaderList.map((one)=> ({
    ...one,
    ref: React.createRef()
}))


const TabIndicator = ({measureLayout, scrollX}) => {

    

    const inputRange = HeadingLis.map((_,i)=> i*width)

    const tabIndicatorWidth = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.width)
    });

    const translateX = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.x)
    })
    

    return(
        <Animated.View 
        style={{position: 'absolute', bottom: 0, height: 3, 
        width: tabIndicatorWidth, borderRadius: 2, backgroundColor: mainColour,
        transform: [{
            translateX
        }]
        }}
        />
    )
}

const Tabio = ({scrollX, onTabPress}) => {

    const [measureLayout, setMeasureLayout] = useState([])
    const containerRef = useRef();

    useEffect(() => {
      let ml = []

      HeadingLis.forEach(two => {
        two?.ref?.current?.measureLayout(
            containerRef.current,
            (x, y, width, height) => {
                ml.push({
                    x, y, width, height
                })

                if(ml.length === HeadingLis.length){
                    setMeasureLayout(ml)
                }
            }
        )
      })
    }, [containerRef.current])
    



    return(
        <View
            ref={containerRef}
            style={{flex:1, flexDirection: 'row', }}
        >
           

            {/* <TabIndicator/> */}

            {measureLayout.length > 0 && <TabIndicator measureLayout={measureLayout} scrollX={scrollX} />}

            {/* Tabs */}
            {HeadingLis.map((item, index) => {
                return(
                    <TouchableOpacity 
                        key={`Tab-${index}`} 
                        ref={item.ref}
                        style={{flex: 1,
                        paddingHorizontal: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                        onPress={()=> {
                            onTabPress(index)
                        }}
                    >
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.item}</Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}





const ManageTicket = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const mainAllY = useRef(new Animated.Value(30)).current

    const EmptyScreen = () => {
        return(
            <View>
                <Text>No Screen</Text>
            </View>
        )
    }

    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current


    const onTabPress = React.useCallback(tabIndex => {
        flatListRef?.current?.scrollToOffset({
            offset: tabIndex * width
                })
            })


    const renderItems = ({item, index})=>{
        return(
            <View style={{width: width}}>
                {/* {index == 0 && <InfoScreen/>} */}
                {index == 0 && <TicketDetails name={route?.item.id}/>}
                {index == 1 && <SalesTicketList name={route?.item.name} event={route?.item.eventId}/>}
            </View>
        )
    }

    const tabs = ()=>{
        return(
            <View style={{height: 50, width: width, borderBottomWidth: 0.5, borderColor: '#d1d1d1', borderTopWidth: 0, backgroundColor: '#fcfcfc'}}>
                <Tabio scrollX={scrollX} onTabPress={onTabPress}/>    
            </View>

        )
    }

    useEffect(() => {
        
        Animated.parallel([
           
            Animated.timing(mainAllY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, []);



    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa', paddingTop: 0}}>
             <View style={{height: '8%', width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderBottomWidth: 0, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Manage Ticket<Text style={{fontSize: 11, fontStyle: 'italic'}}> - {String(route?.item.name).slice(0,20)}...</Text></Text>
                </Animated.View>

                
                
            </View>

            <Animated.View style={{backgroundColor: '#fcfcfc', marginTop: 5, transform: [{translateY: mainAllY}]}}>
                <View style={{marginTop: 0}}>
                {tabs()}
                </View>
           

            <View>
            <Animated.FlatList 
                ref={flatListRef}
                horizontal
                data={HeaderList}
                renderItem={renderItems}
                snapToAlignment="center"
                snapToInterval={width}
                decelerationRate='fast'
                showsHorizontalScrollIndicator={false}
                pagingEnabled
                style={{width: width, height: height}}
                onScroll={Animated.event([
                    {nativeEvent: {contentOffset: {x: scrollX}}}
                ], {
                    useNativeDriver: false
                })}
            />
            </View>


            </Animated.View>



        </View>
    )

}
export default ManageTicket