import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
// import HomeScreen from "../Screens/HomeScreen";
// import HomeStack from "./HomeStack";
import HomeStack from "./HomeStack";
import MenuStack from "./MenuStack";
import MessagesScreen from "../screens/MenuScreens/MessagesScreen";
import CampaignStack from "./CampaignStack";




const Tab = createBottomTabNavigator();
const {width, height} = Dimensions.get('screen')



const Tabs = () => {

    const EmptyScreen = ()=>{
        return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#e4e6eb'}}>
                <View style={{height: 150, width: 150, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', borderRadius: 20}}>
                    <Text>Empty Screens</Text>
                </View>
            </View>
        )
    }

  return (
    <View style={{flex: 1, marginBottom: 0}}>
     
       <Tab.Navigator
       screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarActiveTintColor: '#f22613',
       }}>

      <Tab.Screen name="Home" component={HomeStack} options={{
                tabBarIcon: ({color, size}) => 
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        <Feather name="home" color={color} size={20}/>
                        <Text style={{color: color, fontSize: 10}}>Home</Text>
                    </View>
                    }} />
                    <Tab.Screen name="Campaigns" component={CampaignStack} options={{
                tabBarIcon: ({color, size}) => 
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        <Feather name='book' color={color} size={20}/>
                        <Text style={{color: color, fontSize: 10}}>Campaigns</Text>
                    </View>
                    }} /> 

                    <Tab.Screen name="Messages" component={MessagesScreen} options={{
                        
                tabBarIcon: ({color, size}) => 
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        {/* <Animated.View style={{height: 15, width: 15, zIndex: 2000, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 50, position: 'absolute', right: 0, top: -5}}>
                            <Text style={{fontSize: 8, fontWeight: 'bold', color: '#fcfcfc'}}>12</Text>
                        </Animated.View> */}
                        <Feather name="mail" color={color} size={20}/>
                        <Text style={{color: color, fontSize: 10}}>Messages</Text>
                    </View>
                    }} /> 

                    <Tab.Screen name="Profile" component={MenuStack} options={{
                tabBarIcon: ({color, size}) => 
                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                        <Feather name="users" color={color} size={20}/>
                        <Text style={{color: color, fontSize: 10}}>Profile</Text>
                    </View>
                    }} /> 
    </Tab.Navigator>
    </View>
  )
}

export default Tabs

const styles = StyleSheet.create({
    shadow:{
        shadowColor: '#7F5DF0',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.5,
        elevation: 5
    }
})