import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity} from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { manifest } from "expo-updates";
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUserData } from "../graphql/queries";
import * as queries from '../graphql/queries'


const {width, height} = Dimensions.get('screen')


const HomeScreen = () => {


    const navigation = useNavigation()

    const theorange = '#d14324';
    const theblue = '#226dbf'


    const [transactions, setTransactions] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [pendingBalace, setPendingBalance] = useState(0)


    const WalletMainY = useRef(new Animated.Value(100)).current;  
    const eventsMainY = useRef(new Animated.Value(60)).current;    


    const checkAuth = async()=>{
  
        const userAuthenticated =  await Auth.currentAuthenticatedUser();
        const userSub = userAuthenticated.attributes.sub;
    
    
      try{
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
            const userd = ef.data.getUserData
    
            setUserInfo(userd)
    
          }).catch((ee)=> {
            console.error(ee)
          })
    
      } catch (e){
        console.error(e)
      }


    //   checking pending event balance

    //   try {
    //      await API.graphql({query: queries.listOrders, variables: 
    //         {
    //         filter : { and: [ 
    //             { vendorId: {eq: (userSub)}},
                
    //         ]}
    //     } }).then((ee)=> {
    //      const eeData = ee.data.listOrders.items

    //         const listOfEvents = []

            

    //         eeData.map((ef)=>{
    //             try {
    //                 const eventId = ef.eventId

    //                 API.graphql({query: queries.listEventDays, variables: 
    //                     {
    //                     filter : { and: [ 
    //                         { eventId: {eq: eventId}},
    //                         {startStatus: {ne: 'Complete'}},
    //                     ]}
    //                 } }).then((ees)=> {

    //                     const eesData = ees.data.listEventDays.items

    //                     listOfEvents.push(eesData)
    //                 })

    //             } catch (error) {
    //                 console.error(error)
    //             }
    //         })



           
    //         let totalPrice = 0;

    //      totalPrice = eeData?.reduce((summedPrice, product) => 
    //      (summedPrice + (product?.orderAmount || 0) * 1 ), 
    //      0);

    //      setPendingBalance(totalPrice)
           


            
    //     //  let totalPrice = 0;

    //     //  totalPrice = eeData?.reduce((summedPrice, product) => 
    //     //  (summedPrice + (product?.orderAmount || 0) * 1 ), 
    //     //  0);

    //     //  setPendingBalance(totalPrice)



    //     })
        
       
    //   } catch (error) {
    //     console.error(error)
    //   }
      
    
    
      }


    

    useEffect(() => {

        checkAuth()

        Animated.parallel([
            Animated.timing(WalletMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
            Animated.timing(eventsMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, [userInfo]);


    return(
       <View style={{flex: 1, backgroundColor: '#e3eefa'}}>


            <Animated.View style={{alignItems: 'center',marginTop: 10,  backgroundColor: '#fcfcfc',  borderRadius: 10, paddingTop: 25, paddingBottom: 25, margin: 10, transform: [{translateY: WalletMainY}]}}>
                
                <View style={{position: 'absolute', height: 30, width: 30, borderRadius: 40, backgroundColor: theorange, top: 5, right: 5, alignItems: 'center', justifyContent: 'center'}}>
                   <TouchableOpacity onPress={()=> console.warn('ss')}>
                    <Entypo name= 'wallet' size={12} color="#fcfcfc" />
                   </TouchableOpacity>
                    
                </View>


                <Animated.View style={{flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
                     <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.balance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>available balance</Text>
                    </Animated.View>

                    <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.pendingBalance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>pending balance</Text>
                    </Animated.View>               
                </Animated.View>

                <Animated.View style={{width: '80%', height: 70, backgroundColor: 'rgba(227, 238, 250, 0.5)', flexDirection: 'row', borderRadius: 10, marginTop: 15 }}>
                    
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <TouchableOpacity style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>
                                
                                <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Feather name='list' color='#fcfcfc' size={15} />
                                </View>

                                <Text style={{fontSize: 10,  marginTop: 5}}>History</Text>

                        </TouchableOpacity>

                        <TouchableOpacity 
                        onPress={()=> navigation.navigate('CashOut')}
                        style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                                
                            <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Feather name= 'arrow-down' color='#fcfcfc' size={15} />
                            </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Cashout</Text>

                        </TouchableOpacity>

                        <TouchableOpacity style={{height: 60, width: 60, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>


                        <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                            <Feather name= 'arrow-up' color='#fcfcfc' size={15} />
                        </View>

                            <Text style={{fontSize: 10,  marginTop: 5}}>Payment</Text>

                    </TouchableOpacity>

                    </View>

                    


                </Animated.View>

               

            </Animated.View>

            <Animated.View style={{alignItems: 'center',marginTop: 5, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, marginHorizontal: 10, transform: [{translateY: eventsMainY}]}}>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'area-graph' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Upcomming Events</Text>
                    </View>


                   

                  
                </TouchableOpacity>

              
                        <View style={{marginTop: 50, marginBottom: 30, alignItems: 'center'}}>
                            <Text style={{fontSize: 12, fontStyle: 'italic'}}>No data to display</Text>
                            <Text style={{ fontSize: 12, fontStyle: 'italic'}}>You don't have any events at the moment</Text>

                            <TouchableOpacity onPress={()=> navigation.navigate('Campaigns')} style={{height: 40, width: width / 3, backgroundColor: theblue, marginTop: 15, flexDirection: 'row', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Feather name='plus' color={'#fcfcfc'} size={18} />
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Add Event</Text>
                            </TouchableOpacity>
                        </View>
                     
                    
               





            </Animated.View>



        
        
        
        </View> 
    )
     
}

export default HomeScreen