import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput, FlatList } from "react-native";
import React, {useState, useEffect, Fragment, useRef, useCallback, useMemo} from 'react';
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native'; 
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Auth, graphqlOperation, API } from "aws-amplify";
import { Calendar, CalendarUtils } from 'react-native-calendars';
import { getEvent } from "../../graphql/queries";

const {width, height} = Dimensions.get('screen');

const mainColour = 'purple'

const HeaderList = [
     
    {
        id: 0,
        item: 'Active'
    },
    {
        id: 1,
        item: 'Pending'
    }
    ,
    {
        id: 2,
        item: 'Past'
    },
    {
        id: 3,
        item: 'Other'
    },
           

]

const HeadingLis = HeaderList.map((one)=> ({
    ...one,
    ref: React.createRef()
}))

const TabIndicator = ({measureLayout, scrollX}) => {

    

    const inputRange = HeadingLis.map((_,i)=> i*width)

    const tabIndicatorWidth = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.width)
    });

    const translateX = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.x)
    })
    

    return(
        <Animated.View 
        style={{position: 'absolute', bottom: 0, height: 3, 
        width: tabIndicatorWidth, borderRadius: 2, backgroundColor: mainColour,
        transform: [{
            translateX
        }]
        }}
        />
    )
}

const Tabio = ({scrollX, onTabPress}) => {

    const [measureLayout, setMeasureLayout] = useState([])
    const containerRef = useRef();

    useEffect(() => {
      let ml = []

      HeadingLis.forEach(two => {
        two?.ref?.current?.measureLayout(
            containerRef.current,
            (x, y, width, height) => {
                ml.push({
                    x, y, width, height
                })

                if(ml.length === HeadingLis.length){
                    setMeasureLayout(ml)
                }
            }
        )
      })
    }, [containerRef.current])
    
    


    return(
        <View
            ref={containerRef}
            style={{flex:1, flexDirection: 'row', }}
        >
           

            {/* <TabIndicator/> */}

            {measureLayout.length > 0 && <TabIndicator measureLayout={measureLayout} scrollX={scrollX} />}

            {/* Tabs */}
            {HeadingLis.map((item, index) => {
                return(
                    <TouchableOpacity 
                        key={`Tab-${index}`} 
                        ref={item.ref}
                        style={{flex: 1,
                        paddingHorizontal: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                        onPress={()=> {
                            onTabPress(index)
                        }}
                    >
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.item}</Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}





const TicketEvent = ({name}) => {


    const navigation = useNavigation();

    const theorange = '#d14324';
    const theblue = '#226dbf'
    

    const [allQueries, setQueries] = useState([]);
    const [eventName, setEventName] = useState('');
    const [city, setCity] = useState();
    const [quantity, setQuantity] = useState();
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [Headline, setHeadline] = useState('');
    const [timee, setTimee] = useState('');

    const todayDate = new Date;

    const [selected, setSelected] = useState(INITIAL_DATE);
        const [currentMonth, setCurrentMonth] = useState(INITIAL_DATE);
        const [date, setDate] = useState(new Date(todayDate));
        const [datea, setDatea] = useState('')

        const [toOpt, setToOpt] = useState()

        const [freeEvent, setFreeEvent] = useState(false)

    const AddNewEventMain = useRef(new Animated.Value(width)).current;
    const AddNewEventMainOpacity = useRef(new Animated.Value(0)).current;
    const AddNewEventSmall = useRef(new Animated.Value(width)).current;


    const deleteMainCover = useRef(new Animated.Value(-height)).current;
    const deleteMainOpac = useRef(new Animated.Value(0)).current;

    const deleteButton = useRef(new Animated.Value(-height)).current;

    const INITIAL_DATE = '2023-03-10';

    const getDate = (count: number) => {
        const date = new Date(INITIAL_DATE);
        const newDate = date.setDate(date.getDate() + count);
        return CalendarUtils.getCalendarDateString(newDate);
      };

      const onDayPress = useCallback((day) => {
        setSelected(day.dateString);
      }, []);

      const renderCalendarWithMinAndMaxDates = () => {
        return (
          <Fragment>
            <Calendar
              style={{width: '80%'}}
              hideExtraDays
              current={INITIAL_DATE}
              minDate={getDate(-0)}
              maxDate={getDate(200)}
              disableAllTouchEventsForDisabledDays
              onDayPress={(ee)=> {setDate(ee.dateString), console.log(ee.dateString), setDatea(ee.dateString)}}
            />
          </Fragment>
        );
      };


    
    const animDeleteClose = () => {
        Animated.sequence([
            Animated.spring(deleteButton, {
                toValue: -height,
                useNativeDriver: true
            }),
            Animated.spring(deleteMainOpac, {
                toValue: 0,
                useNativeDriver: true
            }),
                Animated.spring(deleteMainCover, {
                    toValue: -height,
                    useNativeDriver: true
                }),
               
                
            ]).start()
    }

    const animNewEvent = () => {
        Animated.sequence([
                Animated.spring(AddNewEventMain, {
                    toValue: 0,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventMainOpacity, {
                    toValue: 1,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventSmall, {
                    toValue: 0,
                    useNativeDriver: true
                }),
            ]).start()
    }

    const animCloseNewEvent = () => {
        Animated.sequence([
            Animated.spring(AddNewEventSmall, {
                toValue: width,
                useNativeDriver: true
            }), 
            Animated.spring(AddNewEventMainOpacity, {
                toValue: 0,
                useNativeDriver: true
            }),
             
            
            Animated.spring(AddNewEventMain, {
                    toValue: width,
                    useNativeDriver: true
                }),
               
            ]).start()
    }


    const [eventss, setEventss] = useState([])
    const [eventInfo, setEventInfo] = useState()

    const listingEvents = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});

                try {
                    await API.graphql(graphqlOperation(getEvent, { id: name})).then((e)=> {

                        const eData = e.data.getEvent

                    setEventInfo(eData)

                    }) 
                } catch (error) {
                    console.error(error)
                }

            
        
            const allTodos = await API.graphql({query: queries.listTickets, variables: {
                filter: {
                    eventId: {eq: name}
                }
            }})
            
            const todos = allTodos.data.listTickets.items
            setEventss(todos)

            // console.log(todos)

    }


    useEffect(() => {
        listingEvents()
    }, [])





    const emptyItems = () => {
       if(eventss?.filter(cp => !cp.product).length === 0){
        return  (
            <Animated.View style={{alignItems: 'center', backgroundColor: 'white',  marginTop: 0, flex: 1, padding: 30}}>
                
                    <Text style={{alignSelf: 'center', fontSize: 16, fontWeight: 'bold', color: '#233f4d', marginTop: 50}}>You have no tickets</Text>
                   
               
            </Animated.View>
        )
    } 
    }

  

    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current

    const onTabPress = React.useCallback(tabIndex => {
    flatListRef?.current?.scrollToOffset({
        offset: tabIndex * width
            })
        })


       
        const tabs = ()=>{
            return(
                <View style={{height: 50, width: width, borderBottomWidth: 0.5, borderColor: '#d1d1d1', borderTopWidth: 0.5, backgroundColor: '#fcfcfc'}}>
                    <Tabio scrollX={scrollX} onTabPress={onTabPress}/>    
                </View>
    
            )
        }

        const createEvent = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            const eventInput = {
                name : eventName,
                eventId: name,
                amount: city,
                description: description,
                qty: quantity,
                content: 'null',
                active: true
            }
    
    
            try {
                await API.graphql(graphqlOperation(mutations.createTicket, { input: eventInput})).then((ee)=> {
                    listingEvents()
                    animCloseNewEvent()
                })
              } catch (error) {
                console.error(error)
              }
    
        }

        const deleteTicket = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            const eventInput = {
                id : toOpt?.id,
            }
    
    
            try {
                await API.graphql(graphqlOperation(mutations.deleteTicket, { input: eventInput})).then((ee)=> {
                    animDeleteClose()
                })
              } catch (error) {
                console.error(error)
              }
    
              
        }



        const renderCampaigns = ({item}) => {
            return(
                <TouchableOpacity onPress={()=> {navigation.navigate('ManageTicket', {item: item})}} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', alignSelf: 'center', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                    
                    <View style={{width: '20%', height: '80%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(227, 238, 250, 0.99)', borderRadius: 10}}>
                        
                        <Feather name='calendar' size={40} color='gray'/>
                    </View>
    
                    <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 15}}>{item.name || `--`}</Text>  
                      <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 200)} || {item?.qty} tickets</Text>
                    </View>
    
                    <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                        <Feather name='arrow-right' size={20}/>
                    </View>
                    
                </TouchableOpacity>
            )
          }

    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', paddingTop: 0}}>

            <Animated.View style={{height: height / 2, width: width, backgroundColor: 'rgba(0,0,0,0.7)', opacity: AddNewEventMainOpacity, position: 'absolute', zIndex: 6000, transform: [{translateX: AddNewEventMain}]}}>
                    
                    <Animated.View style={{ width: width - 0, backgroundColor: '#fcfcfc', borderRadius: 0, alignSelf: 'center', marginTop: 0, paddingTop: 15, transform: [{translateX: AddNewEventSmall}]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Animated.View style={{ borderRadius: 50, width: 30, height: 30, backgroundColor: 'black', alignItems: 'center', justifyContent:'center', left: 15, top: 0}}>
                            <TouchableOpacity onPress={()=> animCloseNewEvent()}>
                                    <Ionicons name='arrow-back' size={15} color={'#fcfcfc'} />
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View>
                                <Text style={{marginLeft: 25, fontWeight: 'bold', fontSize: 16}}>Add New Ticket</Text>
                            </Animated.View>
                        </View>
                        
                        <View style={{}}>
                            <ScrollView style={{width: '100%', height: height / 2.2, backgroundColor: 'transparent', marginTop: 10, paddingLeft: 25}}>
                            
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Name</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="General" placeholderTextColor={'gray'}  value={eventName} onChangeText={setEventName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                            <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 25, justifyContent: 'space-between', paddingRight: 25}}>
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>Amount (ZAR)</Text>

                        <TouchableOpacity onPress={()=> {setFreeEvent(!freeEvent), setCity(0)}} style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={{fontSize: 10, marginRight: 5}}>Free Event</Text>

                            <View style={{height: 10, width: 10, borderWidth: 0.5, alignItems: 'center', justifyContent: 'center'}}>
                                { freeEvent &&
                                    <View style={{height: 5, width: 5, backgroundColor: 'blue'}} />
                                }
                            </View>
                        </TouchableOpacity>
                        
                    </View>

                    {freeEvent ? 
                        <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                            <TextInput placeholder="0" editable={false} placeholderTextColor={'gray'} value={city} onChangeText={setCity} keyboardType='number-pad' style={{fontSize: 12, height: '100%', width: '100%'}}/>
                        </View>
                            :
                        <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                            <TextInput placeholder="Enter Price" placeholderTextColor={'gray'} value={city} onChangeText={setCity} keyboardType='number-pad' style={{fontSize: 12, height: '100%', width: '100%'}}/>
                        </View>
                    }    

                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Quantity</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Quantity" placeholderTextColor={'gray'} keyboardType='number-pad' value={quantity} onChangeText={setQuantity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                         
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Description</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Ticket Description" placeholderTextColor={'gray'} value={description} onChangeText={setDescription} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                                               

                            
                            <View style={{marginTop: 25, marginBottom: 25, alignSelf: 'center'}}>
                                <TouchableOpacity onPress={()=> createEvent()} style={{height: 45, width: width - 90,  alignItems: 'center', justifyContent: 'center', backgroundColor: theblue, borderRadius: 20}}>
                                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Ticket</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{marginBottom: 100}} />


                            </ScrollView>
                        </View>


                    </Animated.View>
            </Animated.View>

            <Animated.View style={{zIndex: 6000, position: 'absolute', width: width,  height: height, justifyContent: 'space-between', flexDirection: 'row',  opacity: deleteMainOpac, transform: [{
                translateY: deleteMainCover
            }]}}>
                
                <View style={{height: '100%', width: width, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute'}}/>


                <TouchableOpacity onPress={()=> deleteTicket()} style={{height: 50, width: '70%', flexDirection: 'row', backgroundColor: 'red', borderRadius: 0, alignItems: 'center', justifyContent: 'center', transform: [{translateY: deleteButton}]}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 16, marginLeft: 10}}>Delete</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={()=> animDeleteClose()} style={{height: 50, width: '30%', flexDirection: 'row', backgroundColor: 'orange', borderRadius: 0, alignItems: 'center', justifyContent: 'center', transform: [{translateY: deleteButton}]}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 16, marginLeft: 10}}>Cancel</Text>
                </TouchableOpacity>

                

                
            </Animated.View>

            
         
            

            <View style={{width: '100%', height: '100%', paddingTop: 15, alignItems: 'center', backgroundColor: '#fcfcfc'}}>



            <TouchableOpacity onPress={()=> animNewEvent()} style={{height: 40, paddingHorizontal: 10, marginBottom: 20, flexDirection: 'row', marginLeft: 10, backgroundColor: theblue, alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center'}}>
                    <Feather name='plus'  color={'#fcfcfc'} size={18}  />
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Create Ticket</Text>
                </TouchableOpacity>      

                {emptyItems()}


                <FlatList 
                    data={eventss}
                    renderItem={renderCampaigns}
                    // estimatedItemSize={10}
                />
            </View>
            {/* {eventInfo?.startStatus === null && 
            <TouchableOpacity onPress={()=> animNewEvent()} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: theblue, alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 100}}>
                    <Feather name='plus'  color={'#fcfcfc'} size={18}  />
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Create Ticket</Text>
                </TouchableOpacity> 
            } */}

        </View>
    )

}
export default TicketEvent