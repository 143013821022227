import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput, ActivityIndicator } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import { getEventDay, getEvent } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from '../../graphql/mutations';

// import EventQueries from "./EventQueries";
import EventScreen from "./EventScreen";
import SalesEventList from "./SalesEventList";
// import Sales from "./Sales";
import { useRoute } from "@react-navigation/native";
import TicketEvent from "./TicketEvent";

const {width, height} = Dimensions.get('screen');

const mainColour = '#d40404';

const HeaderList = [
     
    {
        id: 0,
        item: 'Tickets'
    },
    {
        id: 1,
        item: 'Sales'
    }
     
]

const HeadingLis = HeaderList.map((one)=> ({
    ...one,
    ref: React.createRef()
}))


const TabIndicator = ({measureLayout, scrollX}) => {

    

    const inputRange = HeadingLis.map((_,i)=> i*width)

    const tabIndicatorWidth = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.width)
    });

    const translateX = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.x)
    })
    

    return(
        <Animated.View 
        style={{position: 'absolute', bottom: 0, height: 3, 
        width: tabIndicatorWidth, borderRadius: 2, backgroundColor: mainColour,
        transform: [{
            translateX
        }]
        }}
        />
    )
}

const Tabio = ({scrollX, onTabPress}) => {

    const [measureLayout, setMeasureLayout] = useState([])
    const containerRef = useRef();

    useEffect(() => {
      let ml = []

      HeadingLis.forEach(two => {
        two?.ref?.current?.measureLayout(
            containerRef.current,
            (x, y, width, height) => {
                ml.push({
                    x, y, width, height
                })

                if(ml.length === HeadingLis.length){
                    setMeasureLayout(ml)
                }
            }
        )
      })
    }, [containerRef.current])
    



    return(
        <View
            ref={containerRef}
            style={{flex:1, flexDirection: 'row', }}
        >
           

            {/* <TabIndicator/> */}

            {measureLayout.length > 0 && <TabIndicator measureLayout={measureLayout} scrollX={scrollX} />}

            {/* Tabs */}
            {HeadingLis.map((item, index) => {
                return(
                    <TouchableOpacity 
                        key={`Tab-${index}`} 
                        ref={item.ref}
                        style={{flex: 1,
                        paddingHorizontal: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                        onPress={()=> {
                            onTabPress(index)
                        }}
                    >
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.item}</Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}





const ManageEvent = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const mainAllY = useRef(new Animated.Value(30)).current

    const EmptyScreen = () => {
        return(
            <View>
                <Text>No Screen</Text>
            </View>
        )
    }

    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current


    const onTabPress = React.useCallback(tabIndex => {
        flatListRef?.current?.scrollToOffset({
            offset: tabIndex * width
                })
            })


    const renderItems = ({item, index})=>{
        return(
            <View style={{width: width}}>
                {/* {index == 0 && <InfoScreen/>} */}
                {index == 0 && <TicketEvent name={route?.item.id}/>}
                {index == 1 && <SalesEventList name={route?.item.id}/>}
            </View>
        )
    }

    const tabs = ()=>{
        return(
            <View style={{height: 50, width: width, borderBottomWidth: 0.5, borderColor: '#d1d1d1', borderTopWidth: 0, backgroundColor: '#fcfcfc'}}>
                <Tabio scrollX={scrollX} onTabPress={onTabPress}/>    
            </View>

        )
    }

    const [eventInfo, setEventInfo] = useState()
    const [spinning, setSpinning] = useState(false)


    const fetchEventDetails = async()=> {


        try {

            await API.graphql(graphqlOperation(getEvent, { id: route?.item.id})).then((e)=> {

                const eData = e.data.getEvent

              setEventInfo(eData)
            })

        } catch (error) {
            console.error(error)
        }

    }






    useEffect(() => {
        fetchEventDetails()
        
        Animated.parallel([
           
            Animated.timing(mainAllY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, [eventInfo]);

      const updateEventStatus = async()=> {

        if(eventInfo?.startStatus === null){

            setSpinning(true)

            const inputM = {
                id: route?.item.id,
                startStatus: 'Started'
            }

            try {
                await API.graphql({ query: mutations.updateEvent, variables: {input: inputM}}).then((ess)=> {
                    setSpinning(false)
                })
    
            } catch (error) {
                console.error(error),
                setSpinning(false)
                return
            }

          


        }

        
        if(eventInfo?.startStatus === 'Started'){

            setSpinning(true)

            const inputM = {
                id: route?.item.id,
                startStatus: 'RequestedFunds'
            }

            try {
                await API.graphql({ query: mutations.updateEvent, variables: {input: inputM}}).then((ess)=> {
                    setSpinning(false)
                })
    
            } catch (error) {
                console.error(error),
                setSpinning(false)
                return
            }

          


        }

        if(eventInfo?.startStatus === 'Ended'){

            setSpinning(true)

            const inputM = {
                id: route?.item.id,
                startStatus: 'RequestedFunds'
            }

            try {
                await API.graphql({ query: mutations.updateEvent, variables: {input: inputM}}).then((ess)=> {
                    setSpinning(false)
                })
    
            } catch (error) {
                console.error(error),
                setSpinning(false)
                return
            }

          


        }


      }

    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa', paddingTop: 0}}>
             <View style={{height: '8%', width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderBottomWidth: 0, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Manage Event<Text style={{fontSize: 11, fontStyle: 'italic'}}> - {String(route?.item.city).slice(0,20)}...</Text></Text>
                </Animated.View>           
                
            </View>

            <Animated.View style={{backgroundColor: '#fcfcfc', marginTop: 5, transform: [{translateY: mainAllY}]}}>
                <View style={{marginTop: 0}}>
                {tabs()}
                </View>
           

            <View>
            <Animated.FlatList 
                ref={flatListRef}
                horizontal
                data={HeaderList}
                renderItem={renderItems}
                snapToAlignment="center"
                snapToInterval={width}
                decelerationRate='fast'
                showsHorizontalScrollIndicator={false}
                pagingEnabled
                style={{width: width, height: height}}
                onScroll={Animated.event([
                    {nativeEvent: {contentOffset: {x: scrollX}}}
                ], {
                    useNativeDriver: false
                })}
            />
            </View>

                {eventInfo?.startStatus === null &&
                
                <TouchableOpacity onPress={()=> {updateEventStatus()}} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: '#031e4a', alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 50}}>
                {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'}/> : <Feather name='arrow-up-right'  color={'#fcfcfc'} size={18}  /> }
                <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Start Event</Text>
                </TouchableOpacity>     
                }

                {eventInfo?.startStatus === 'Started' &&
                
                <TouchableOpacity onPress={()=> {updateEventStatus()}} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: 'red', alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 50}}>
                {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'}/> : <Feather name='arrow-up-right'  color={'#fcfcfc'} size={18}  /> }
                <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>End Event</Text>
                </TouchableOpacity>     
                }

                {eventInfo?.startStatus === 'Ended' &&
                
                <TouchableOpacity onPress={()=> {updateEventStatus()}} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: 'green', alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 50}}>
                {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'}/> : <Feather name='arrow-up-right'  color={'#fcfcfc'} size={18}  /> }
                <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Request Funds</Text>
                </TouchableOpacity>     
                }
                  
                  {eventInfo?.startStatus === 'RequestedFunds' &&
                
                <View onPress={()=> {updateEventStatus()}} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: 'lightblue', alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 50}}>
                    {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'}/> : <Feather name='arrow-up-right'  color={'#fcfcfc'} size={18}  /> }
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>..Pending Allocation</Text>
                </View>     
                }

            </Animated.View>



        </View>
    )

}
export default ManageEvent