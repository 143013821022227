import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, ScrollView, Animated, TextInput , TouchableOpacity, Dimensions, ActivityIndicator, ImageBackground } from 'react-native';
import React, {useState, useCallback, useRef, useEffect} from 'react';
import { LinearGradient  } from 'expo-linear-gradient';
import { AntDesign, Entypo, Feather } from '@expo/vector-icons';
import { getUserData } from './src/graphql/queries';
import * as mutations from './src/graphql/mutations';
import * as queries from './src/graphql/queries';
import TabNav from './src/router/TabNav';

const {width, height} = Dimensions.get('screen');

import { Amplify, API, Auth, graphqlOperation } from 'aws-amplify';
import awsconfig from './src/aws-exports';
Amplify.configure(awsconfig)


export default function App() {

  const [firstName, ChangeFirstName] = React.useState('');
  const [lastName, ChangeLastName] = React.useState('');

  const [emailLogin, ChangeEmailLogin] = React.useState('');
  const [emailPassword, ChangeEmailPassword] = React.useState('');

   const [new_password, ChangeNew_password] = React.useState('');

  const [signupEmail, ChangeSignEmail] = React.useState('');


  const [IdNumber, ChangeIdNumber] = React.useState('');
  const [IdcountryIssue, ChangeIdcountryIssue] = React.useState('');
  const [businessName, ChangebusinessName] = React.useState('');
  const [businessAddress, ChangebusinessAddress] = React.useState('');




  const [phoneNumber, ChangePhoneNumber] = React.useState(null);
  const [signupPassword, ChangeSignUpPassword] = React.useState('');
  const [confirmPassword, ChangeConfirmPassword] = React.useState('');
  const [otpA, ChangeOtpA] = React.useState('');

  const [spinning, setSpinning] = useState(false);
  const [theMsg, setTheMsg] = React.useState('')

  const [choose, setChoose] = useState('main');
  const [profileStep, setProfileStep] = useState('name')
 
  const [userInfo, setUserInfo] = useState();


  const [fnError, setfnError] = useState('')
  const [fnErrorMessage, setfnErrorMessage] = useState('Missing Information')






  const ourOrange = '#FF4F00';


  const opacityA = useRef(new Animated.Value(0)).current;
  const opacityB = useRef(new Animated.Value(0)).current;
  const opacityF = useRef(new Animated.Value(0)).current;
  const opacityE = useRef(new Animated.Value(0)).current;


  // new Main Screen Animations
  const ExplTextY = useRef(new Animated.Value(height)).current; 
  const exploreButtonY = useRef(new Animated.Value(height)).current; 

  const explOpac = useRef(new Animated.Value(1)).current; 

  const loginMain = useRef(new Animated.Value(width)).current;
  const loginContentY = useRef(new Animated.Value(90)).current;
  const loginMainOpac = useRef(new Animated.Value(0)).current;

  const registerMain = useRef(new Animated.Value(width)).current;
  const registerMainOpac = useRef(new Animated.Value(1)).current;
  const otp = useRef(new Animated.Value(width)).current;



  const menuModal = useRef(new Animated.Value(height)).current;
  const bgMainY = useRef(new Animated.Value(width)).current;
  const bgMainOpac = useRef(new Animated.Value(0)).current;

  const mainImage1 = 'https://images.unsplash.com/photo-1616166358812-784a766b5762?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  const newImage = 'https://images.unsplash.com/photo-1516450137517-162bfbeb8dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
  const mainImage = 'https://images.pexels.com/photos/9789915/pexels-photo-9789915.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

  const [showPassword, setShowPassword] = useState(true)
  const errorMainY = useRef(new Animated.Value(-width)).current;




  const checkAuth = async()=>{
  
    const userAuthenticated =  await Auth.currentAuthenticatedUser();
    const userSub = userAuthenticated.attributes.sub;


  try{
        await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
        const userd = ef.data.getUserData

        setUserInfo(userd)

        setChoose('donr')
        return
      }).catch((ee)=> {
        console.error(ee)
      })

  } catch (e){
    console.error(e)
    setChoose('main')
  }
  


  }

  



  useEffect(() => {

    checkAuth();

    Animated.timing(opacityA, {
      toValue: 1,
      duration: 800,
      delay: 250,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacityB, {
      toValue: 1,
      duration: 900,
      delay: 350,
      useNativeDriver: true,
    }).start();

    Animated.timing(exploreButtonY, {
      toValue: 0,
      duration: 1000,
      delay: 250,
      useNativeDriver: true,
    }).start();

    Animated.timing(ExplTextY, {
      toValue: 0,
      duration: 1000,
      delay: 0,
      useNativeDriver: true,
    }).start();

  }, [userInfo]);


  const exploreToLoginAnim = () => {

    Animated.parallel([
      Animated.timing(exploreButtonY, {
        toValue: height,
        duration: 1000,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(ExplTextY, {
        toValue: height,
        duration: 10000,
        delay: 250,
        useNativeDriver: true,
      }),
      Animated.timing(explOpac, {
        toValue: 0,
        duration: 350,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(loginMain, {
        toValue: 0,
        duration: 750,
        delay: 350,
        useNativeDriver: true,
      }),
      Animated.timing(loginContentY, {
        toValue: 0,
        duration: 750,
        delay: 350,
        useNativeDriver: true,
      }),
      Animated.timing(loginMainOpac, {
        toValue: 1,
        duration: 750,
        delay: 350,
        useNativeDriver: true,
      }),

    ]).start()
    
    


  }

  const animLogToReg = () => {
    Animated.parallel([
      
      Animated.timing(loginMain, {
        toValue: -width,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(registerMain, {
        toValue: 0,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(registerMainOpac, {
        toValue: 1,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),

    ]).start()
  }

  const animSignToLog = () => {
    Animated.parallel([
      
      Animated.timing(loginMain, {
        toValue: 0,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(registerMain, {
        toValue: width,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
     
    ]).start()
  }

  const animSignToOtp = () => {
    Animated.parallel([
      
      Animated.timing(otp, {
        toValue: 0,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
      Animated.timing(registerMain, {
        toValue: -width,
        duration: 750,
        delay: 0,
        useNativeDriver: true,
      }),
     
    ]).start()
  }


  async function signIn() {
    setSpinning(true)
    
    if(!emailLogin.includes('@') &&  !emailLogin.includes('.')){
      setTheMsg('Please enter a valid email address'),
      animError(),
      setSpinning(false)
      return;
    }

      await Auth.signIn(emailLogin, emailPassword).then((user) => {
        checkAuth(),
        setChoose('oka')
        setSpinning(false)
    }).catch(err => 
      {
        console.log(err),
        setTheMsg(err.message),
        animError(),
        setSpinning(false)         
      }

)

  }

  async function putUp(sub) {

    // console.log(sub)

   const date = new Date();
  //  const countryCode = '+27'
  //  const finalNumber = countryCode.concat('', phoneNumber)


   const userInput = {
       id: sub,
       firstName: firstName,
      //  phoneNumber: finalNumber,
       email: emailLogin,
     }

await API.graphql({query: mutations.createUserData, variables: {input: userInput}}).then((e)=> {
  animSignToOtp()
})

}





  async function signUp() {
    setSpinning(true)

    if(firstName < 2){
      setTheMsg('Please enter your first name'),
      animError(),
      setSpinning(false)
      return
    }

    if(emailLogin < 2){
      setTheMsg('Please enter your email address'),
      animError(),
      setSpinning(false)
      return
    }

    if(!emailLogin.includes('@')){
      setTheMsg('Please enter a valid email address'),
      animError(),
      setSpinning(false)
      return
    }

    if(!emailLogin.includes('.')){
      setTheMsg('Please enter a valid email address'),
      animError(),
      setSpinning(false)
      return
    }

    if(emailPassword < 8){
      setTheMsg('Password must be 8 character or more'),
      animError(),
      setSpinning(false)
      return
    }

      await Auth.signUp({
        username: emailLogin,
        password: emailPassword,
        attributes: {
            email: emailLogin,
        },
        autoSignIn: {
          enabled: true
        }
    }).then((ee)=>{ 
      setTheMsg(''),
      
      animSignToOtp(),
      setSpinning(false)
      putUp(ee.userSub)     

  
    }).catch(err => 

      {  console.log(err),
      setTheMsg(err.message),
      animError(),
      setSpinning(false)
    }
      )


    
  }

  async function signInS() {
    await Auth.signIn(emailLogin, emailPassword).then(user => {
      // console.log('success sign in'),
      setChoose('profile'),
      checkAuth(),
      setSpinning(false)
   }).catch(err => {
     console.log(err)
   })

 }


  async function confirmSignUp(){
    setSpinning(true)
    

    Auth.confirmSignUp(emailLogin, otpA)
    .then((ee)=> 
      {
        signInS(),
        setTheMsg(''),
        setSpinning(false)
      
    }

    ).catch(err => {
      console.log(err),
      setTheMsg('Failed to confirm'),
      setSpinning(false)
    })
  }

  const animMenu = () => {

    Animated.parallel([
      Animated.timing(bgMainY,  {
        toValue: 0,
        duration: 1,
        useNativeDriver: true
      }),
      Animated.timing(bgMainOpac,  {
        toValue: 1,
        duration: 500,
        delay: 10,
        useNativeDriver: true
      }),
      Animated.spring(menuModal,  {
        toValue: 0,
        delay: 500,
        useNativeDriver: true
      }),

    ]).start()

  }

  const animCloseMenu = () => {

    Animated.parallel([

      Animated.spring(menuModal,  {
        toValue: height,
        useNativeDriver: true
      }),
      Animated.timing(bgMainY,  {
        toValue: width,
        duration: 1,
        delay: 750,
        useNativeDriver: true
      }),
      Animated.timing(bgMainOpac,  {
        toValue: 0,
        duration: 500,
        delay: 500,
        useNativeDriver: true
      }),

    ]).start()

  }

  const signout =async() => {

    setSpinning(true)

    try {
      await Auth.signOut().then((ee)=> {
        animCloseMenu(),
        setChoose('main'),
        animCloseMenu(),
        setSpinning(false)
      })
    } catch (error) {
      console.error(error),
      setSpinning(false)
    }

  }


  const submitUpdate = async()=> {

    if( !userInfo?.firstName && !firstName){
      setfnError('fn')
      return
    } else if(!userInfo?.IdNumber && !IdNumber){
      setfnError('id')
      return
    }else if(!userInfo?.IdcountryIssue && !IdcountryIssue){
      setfnError('idCountry')
      return
    }else if(!userInfo?.phoneNumber && !phoneNumber){
      setfnError('pn')
      return
    }else if(!userInfo?.businessName && !businessName){
      setfnError('busN')
      return
    }else if(!userInfo?.businessAddress && !businessAddress){
      setfnError('busAdd')
      return
    }


    const userId = await Auth.currentAuthenticatedUser();

    setSpinning(true)


      const userSub = userId.attributes.sub
      
      const inputM = {
        id: userSub,
        email: userId.attributes.email || `Not Found`,
        IdNumber: IdNumber || `Not Found`,
        IdcountryIssue: IdcountryIssue || `Not Found`,
        phoneNumber: phoneNumber || `Not Found`,
        businessName: businessName || `Not Found`,
        businessAddress: businessAddress || `Not Found`
      }

      const inputVendor = {
        id: userSub,
        email: userId.attributes.email,
        firstName: userInfo?.firstName || firstName,
        IdNumber: userInfo?.IdNumber || IdNumber,
        IdcountryIssue: IdcountryIssue,
        phoneNumber: userInfo?.phoneNumber || phoneNumber,
        businessName: businessName,
        businessAddress: businessAddress
      }

    try {

      await API.graphql({ query: mutations.updateUserData, variables: {input: inputM}}).then((e)=> {
        API.graphql({ query: mutations.createVendorData, variables: {input: inputVendor}}).then((ee)=> {
          checkAuth(),
        setSpinning(false)
        })
        
      }).catch((error)=> {
        console.warn(error)
      }) 

    } catch (error) {
      console.error(error),
      setfnError('fn'),
      setSpinning(false)
    }
  }

  const animError = () => {
    Animated.sequence([
      Animated.spring(errorMainY, {
        toValue: 0,
        useNativeDriver: true
      }),
      Animated.delay(2000),
      Animated.spring(errorMainY, {
        toValue: -width,
        useNativeDriver: true
      }),
    ]).start()
  }


  const menuheight = 50
 

  if(choose === 'main'){

   

    return(
      <Animated.View style={{flex: 1, backgroundColor: 'black'}}>
        <ImageBackground style={{width: width, height: height}} source={{uri: newImage}}>

        
        <LinearGradient style={{width: '100%', height: '100%', alignItems: 'flex-end', justifyContent: 'space-evenly'}} colors={['transparent', 'rgba(0,0,0,0.4)', 'rgba(0,0,0,0.9)', 'rgba(0,0,0,0.999999999)']}>
          
          <View style={{height: '100%', width: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
            
            <Animated.View style={{width: width - 90, marginBottom: 50, marginLeft: 0, alignItems: 'center', opacity: explOpac, transform: [{translateY: ExplTextY}]}}>
              <Text style={{fontSize: 18, color: 'white', textAlign: 'center'}}>A quick digital experience for great <Text style={{fontWeight: 'bold', color: ourOrange}}>events</Text></Text>
            </Animated.View>

           

          <Animated.View style={{width: '100%', alignItems: 'flex-end', opacity: opacityE, marginBottom: 15, opacity: explOpac, justifyContent: 'space-evenly', flexDirection: 'row', transform: [{translateY: exploreButtonY}]}}>
                 <TouchableOpacity onPress={()=> { exploreToLoginAnim()
                  //  setChoose('login'), setTheMsg('')
                   
                   }} style={{width: '75%', height: 40, backgroundColor: ourOrange, borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{fontSize: 12, color: 'white', fontWeight: 'bold'}}>Get Started</Text>
                    
                  </TouchableOpacity>
          </Animated.View>
          <Animated.View style={{marginBottom: height/10, width: '100%', opacity: explOpac, alignItems: 'center', marginLeft: 10, marginRight: 10, transform: [{translateY: exploreButtonY}]}}>
            <Text style={{color: 'white', fontSize: 10, alignSelf: 'center', textAlign: 'center', width: width - 90}} >Read our Terms and Conditions Of Use. All rights reserved to REDSNAGISH (PTY) LTD</Text>
          </Animated.View>
          

          
          </View>


        </LinearGradient>
        </ImageBackground>

        {/* sign in */}
       
        <Animated.View style={{height: height, width: width, backgroundColor: 'white', position: 'absolute', opacity: loginMainOpac, transform: [{translateX: loginMain}]}}>
        <ScrollView>
                  <Animated.View style={{height: 100, width: width, backgroundColor: 'red', position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'flex-end', transform: [{translateY: errorMainY}]}}>
                    <Text style={{color: '#fcfcfc', marginBottom: 20, fontWeight: 'bold'}}>{theMsg}</Text>
                  </Animated.View>


                   <Animated.View style={{padding: 15, transform: [{translateY: loginContentY}]}}>
                      
                      <View style={{marginTop: 50}}>
                        <Image source={require('./assets/logohor.png')}  style={{height: 100, width: width, resizeMode: 'contain'}}/>
                      </View>
                      
                      
                      <View style={{marginTop: 15, marginBottom: 20, width: '90%', alignSelf: 'center'}}>
                        <Text style={{fontSize: 18}}>Sign In</Text>
                        <Text style={{fontSize:12, opacity: 0.5, textAlign: 'justify', marginTop: 5}}>Please enter your ticketday login details to continue, if you had already signed up on ticketday.co.za enter those details</Text>
                      </View>

                      <View style={{marginBottom: 10}}>
                        
                      

                      <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 10, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>E-Mail Address</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='email@gmail.com' style={{height: '100%', width: '100%'}} onChangeText={ChangeEmailLogin} value={emailLogin} />
                      </View>

                    </View>

                    <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 50, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>Password</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='Password' secureTextEntry={showPassword} style={{height: '100%', width: '100%'}} onChangeText={ChangeEmailPassword} value={emailPassword} />
                     
                        <View style={{position: 'absolute', right: 15}}>
                          {showPassword ? 
                          <TouchableOpacity onPress={()=> setShowPassword(false)}>
                            <Feather name='eye-off' size={15} />
                          </TouchableOpacity>
                          : 
                          <TouchableOpacity onPress={()=> setShowPassword(true)}>
                            <Feather name='eye' size={15} />
                          </TouchableOpacity>

                          }
                        </View>
                      </View>

                    </View>
                      </View>

                      {/* <View style={{marginBottom: 10}}>
                        <View style={{marginBottom: 10, marginTop: 15}}>
                        <Text style={{fontSize: 13}}>Password</Text>

                        <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
                      
                          <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                            <TextInput placeholder='Password' style={{height: '100%', width: '100%'}} onChangeText={ChangeEmailPassword} value={emailPassword} />

                          </View>
                        </View>
                        </View>
                      </View> */}
                      
                      <Animated.View style={{flexDirection: 'row', width: '90%', alignSelf: 'center', justifyContent: 'space-between', marginLeft: 0, marginTop: 40}}>

                      <TouchableOpacity onPress={()=> { animLogToReg(),  setTheMsg('')}}>
                        <Text  style={{ color: '#000', fontSize: 11}}>No account ? Sign Up</Text>
                      </TouchableOpacity>

                      <TouchableOpacity onPress={()=> {setChoose('forgot')}}>
                        <Text  style={{ color: '#000', fontSize: 11}}>Forgot Password ?</Text>
                      </TouchableOpacity>

                   
                      </Animated.View>

                      {/* <Text style={{fontSize: 11, color: 'red', marginBottom: -20, marginTop: 5, alignSelf: 'center'}}>{theMsg}</Text> */}

                      <Animated.View  style={{flexDirection: 'row', marginLeft: 0, marginRight: 0, marginTop: 20, justifyContent: 'center'}}>
                        

                        <TouchableOpacity onPress={()=> {signIn()}} style={{height: 45, alignSelf: 'center', flexDirection: 'row', width: '90%', borderRadius: 5, backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>

                       {spinning && 
                       <View style={{position: 'absolute', left: 15}}>
                        <ActivityIndicator size={'small'} color={'#fcfcfc'} />
                       </View>
                       }



                          <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Login</Text>
                          <AntDesign name='arrowright' size={15} color={'white'}/>
                        </TouchableOpacity>


                      </Animated.View>


                   </Animated.View>

                      <View style={{height: width / 2, width: 10}} />

                   </ScrollView>
        </Animated.View>
        

         {/* sign up           */}

         <Animated.View style={{height: height, width: width, backgroundColor: '#fcfcfc', position: 'absolute', opacity: registerMainOpac, transform: [{translateX: registerMain}]}}>
         <ScrollView>
               <Animated.View style={{height: 100, width: width, backgroundColor: 'red', position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'flex-end', transform: [{translateY: errorMainY}]}}>
                    <Text style={{color: '#fcfcfc', marginBottom: 20, fontWeight: 'bold'}}>{theMsg}</Text>
                  </Animated.View>           


            <Animated.View style={{padding: 15, transform: [{translateY: loginContentY}]}}>
              

              <View style={{marginTop: 80, marginBottom: 20, width: '90%', alignSelf: 'center'}}>
                        <Text style={{fontSize: 18}}>Create an account</Text>
                        <Text style={{fontSize:12, opacity: 0.5, textAlign: 'justify', marginTop: 5}}>Please enter your ticketday login details to continue, if you had already signed up on ticketday.co.za enter those details</Text>
                      </View>

           

              <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 10, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>First Name</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='First Name' style={{height: '100%', width: '100%'}} onChangeText={ChangeFirstName} value={firstName} />
                      </View>

              </View>

              <View style={{width: '90%',  alignSelf: 'center', borderWidth: 0, marginTop: 50, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>E-Mail Address</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='email@gmail.com' style={{height: '100%', width: '100%'}} onChangeText={ChangeEmailLogin} value={emailLogin} />
                      </View>

              </View>

              <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 50, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>Password</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='Password' secureTextEntry={showPassword} style={{height: '100%', width: '100%'}} onChangeText={ChangeEmailPassword} value={emailPassword} />
                     
                        <View style={{position: 'absolute', right: 15}}>
                          {showPassword ? 
                          <TouchableOpacity onPress={()=> setShowPassword(false)}>
                            <Feather name='eye-off' size={15} />
                          </TouchableOpacity>
                          : 
                          <TouchableOpacity onPress={()=> setShowPassword(true)}>
                            <Feather name='eye' size={15} />
                          </TouchableOpacity>

                          }
                        </View>
                      </View>

                    </View>
              
              <Animated.View style={{flexDirection: 'row', justifyContent: 'space-between', marginLeft: 0, marginTop: 50, width: '90%', alignSelf: 'center'}}>

              <TouchableOpacity onPress={()=> {animSignToLog(),  setTheMsg('')}}>
                <Text  style={{ color: '#000', fontSize: 11}}>Already have an account ? Sign In</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> {}}>
                <Text  style={{ color: '#000', fontSize: 11}}>Confirm Account</Text>
              </TouchableOpacity>


              </Animated.View>

              <Animated.View  style={{flexDirection: 'row', marginLeft: 0, marginRight: 0, marginTop: 20, justifyContent: 'center', alignItems: 'center'}}>
                

                <TouchableOpacity onPress={()=> {signUp()}} style={{height: 45, flexDirection: 'row', width: '90%', borderRadius: 5,alignSelf: 'center',  backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>
                {spinning && 
                       <View style={{position: 'absolute', left: 15}}>
                        <ActivityIndicator size={'small'} color={'#fcfcfc'} />
                       </View>
                       }
                 
                  <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Create Account</Text>
                  <AntDesign name='arrowright' size={15} color={'white'}/>
                </TouchableOpacity>


              </Animated.View>


            </Animated.View>
            <View style={{height: width / 2, width: 10}} />          
            </ScrollView>
        </Animated.View>  


        {/* OTP */}

        <Animated.View style={{height: height, width: width, backgroundColor: '#fcfcfc', position: 'absolute', opacity: registerMainOpac, transform: [{translateX: otp}]}}>

          <Animated.View style={{padding: 15, transform: [{translateY: loginContentY}]}}>
           

            <View style={{marginTop: 80, marginBottom: 20, width: '90%', alignSelf: 'center'}}>
                        <Text style={{fontSize: 18}}>Confirm OTP</Text>
                        <Text style={{fontSize:12, opacity: 0.5, textAlign: 'justify', marginTop: 5}}>Check your otp sent to {emailLogin}</Text>
                      </View>

            

            <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 10, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>E-Mail Address</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput style={{height: '100%', width: '100%'}} value={emailLogin} editable={false} />
                      </View>

              </View>

              <View style={{width: '90%', alignSelf: 'center', borderWidth: 0, marginTop: 50, borderColor: 'black', height: 45, backgroundColor: 'transparent', borderRadius: 5, marginLeft: 0}}>
                      <Text style={{fontSize: 13}}>OTP</Text>
                      <View style={{height: '100%', width: '100%', borderWidth: 0.5, borderRadius: 5, marginTop: 10, borderColor: '#d1d1d1', justifyContent:'center', paddingLeft: 25}}>
                        <TextInput placeholder='OTP' style={{height: '100%', width: '100%'}} onChangeText={ChangeOtpA} value={otpA} />
                      </View>

              </View>

          

         

            <Animated.View  style={{flexDirection: 'row', marginLeft: 0, marginRight: 0, marginTop: 50, justifyContent: 'center',}}>
              

              <TouchableOpacity onPress={()=> {confirmSignUp()}} style={{height: 45, flexDirection: 'row', width: '90%', borderRadius: 5, backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>

                <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Confrim OTP</Text>
                <AntDesign name='arrowright' size={15} color={'white'}/>
              </TouchableOpacity>


            </Animated.View>


          </Animated.View>


        </Animated.View>             







      </Animated.View>
    )
  }

  


  return (
    <View style={{ flex: 1}}>

    <Animated.View style={{height: 90}}>
      <View style={{height: '100%', paddingLeft: 10, paddingRight: 20, paddingBottom: 5,  flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
           <Animated.View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity style={{height: 40, width: 40, borderWidth: 0.5, borderColor: '#04070f', borderRadius: 50, alignItems: 'center', justifyContent: 'center'}}>
                <Image style={{height: 35, width: 35, borderRadius: 50,}} source={require('./src/assets/b.png')}/>
            </TouchableOpacity>
            <Text style={{marginLeft: 10, fontWeight: 'bold', fontSize: 13}}>Hey {userInfo?.firstName || ` ... `}</Text>
            </Animated.View>
            
           
 
                
            <Animated.View style={{flexDirection: 'row'}}>
              <TouchableOpacity onPress={()=> {}} style={{marginBottom: 10, marginRight: 15}}>
                <AntDesign name= 'message1' size={20} color="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=> {animMenu()}} style={{marginBottom: 10}}>
                <AntDesign name="appstore-o" size={20} color="black" />
            </TouchableOpacity>
            </Animated.View>
        </View>
    </Animated.View>

    <Animated.View style={{position: 'absolute', width: width, height, zIndex: 1000, backgroundColor: 'rgba(0,0,0,0.9)', opacity: bgMainOpac, transform:[{translateX: bgMainY}]}}>
        <TouchableOpacity onPress={()=> {animCloseMenu()}} style={{height: '100%', width: '100%'}}>

        </TouchableOpacity>
    </Animated.View>

    <Animated.View style={{height: 70, width: width - 30,  backgroundColor: '#fcfcfc', position: 'absolute', bottom: 100, zIndex: 10000, alignSelf: 'center', borderRadius: 10, transform: [{translateY: menuModal}]}}>
                <View style={{width: '100%', height: '100%', paddingTop: 10}}>
                    {/* <TouchableOpacity onPress={()=> console.warn('profile')} style={{height: menuheight, width: '100%', borderBottomWidth: 0, flexDirection: 'row', alignItems: 'center', padding: 15}}>
                        <Feather name='user' size={20} style={{width: 60, marginLeft: 15}}/>
                        <Text style={{fontSize: 15}}>Profile</Text>
                    </TouchableOpacity>

                    <View style={{height: 2, backgroundColor: '#d1d1d1', width: '100%', opacity: 0.2}}/> */}

                    {/* <TouchableOpacity onPress={()=> {}} style={{height: menuheight, width: '100%', borderBottomWidth: 0, flexDirection: 'row', alignItems: 'center', padding: 15}}>
                        <Feather name= 'map' size={20} style={{width: 60, marginLeft: 15}}/>
                        <Text style={{fontSize: 15}}>Option</Text>
                        <View style={{position: 'absolute', right: 30}}>
                        
                            
                        </View>
                    </TouchableOpacity> */}

                    {/* <View style={{height: 2, backgroundColor: '#d1d1d1', width: '100%', opacity: 0.2}}/> */}

                    {/* <TouchableOpacity onPress={()=> console.warn('Settings')} style={{height: menuheight, width: '100%', borderBottomWidth: 0, flexDirection: 'row', alignItems: 'center', padding: 15}}>
                        <Feather name= 'settings' size={20} style={{width: 60, marginLeft: 15}}/>
                        <Text style={{fontSize: 15}}>Settings</Text>
                    </TouchableOpacity>


                    <View style={{height: 2, backgroundColor: '#d1d1d1', width: '100%', opacity: 0.2}}/> */}

                    <TouchableOpacity  onPress={()=> signout()} style={{height: menuheight, width: '100%', borderBottomWidth: 0, flexDirection: 'row', alignItems: 'center', padding: 15}}>
                        <Feather name= 'arrow-down' size={20} style={{width: 60, marginLeft: 15}}/>
                        <Text style={{fontSize: 15}}>Sign Out</Text>

                        <View style={{position: 'absolute', right: 30}}>
                            {spinning ? <ActivityIndicator color={'orange'} size={'small'}/> : <View/>}
                            
                        </View>


                    </TouchableOpacity>
                </View>
    </Animated.View>

    {/* missing info */}
{/* 
    <Animated.View style={{height: height, width: width, backgroundColor: '#fcfcfc', position: 'absolute', zIndex: 50000, paddingTop: 25}}>

      <ScrollView showsVerticalScrollIndicator={false}>

          <Animated.View style={{margin: 15}}>
            <Text style={{fontWeight: 'bold', fontSize: 20}}>Missing Information</Text>

            <Text style={{marginTop: 15, textAlign: 'justify', fontSize: 12}}>Looks like theres a bit information we dont have about you. Please enter below to continue</Text>
          </Animated.View>


          <Animated.View style={{margin: 15}}>

                  {!userInfo?.firstName ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>First Name</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='John' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeFirstName} value={firstName} />
              </View>
              

              </View>

              {fnError === 'fn' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Missing Information`}</Text> : <View />} 

              </View>
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.IdNumber ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>ID / Passport Number</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='************' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeIdNumber} value={IdNumber} />
              </View>

            </View>
            {fnError === 'id' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
              </View>
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              {!userInfo?.IdcountryIssue ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Country Of Issue</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='South Africa' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeIdcountryIssue} value={IdcountryIssue} />
              </View>

            </View>
                        </View>
                        {fnError === 'idCountry' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.phoneNumber ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Phone Number</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='0662421186' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangePhoneNumber} value={phoneNumber} />
              </View>

            </View>
                        </View>
                        {fnError === 'pn' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.businessName ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Business Name</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='Johnnie Events' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangebusinessName} value={businessName} />
              </View>

            </View>
                        </View>
                        {fnError === 'busN' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              {!userInfo?.businessAddress ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Business Address</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='45 Commissioner Street, Johannesburg, South Africa' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangebusinessAddress} value={businessAddress} />
              </View>

            </View>
                        </View>
                        {fnError === 'busAdd' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              <Animated.View  style={{flexDirection: 'row', marginLeft: 0, marginRight: 0, marginTop: 35, justifyContent: 'space-between'}}>
                

                <TouchableOpacity onPress={()=> {{submitUpdate()}}} style={{height: 45, flexDirection: 'row', width: width - 30, borderRadius: 5, backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>
                  <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Update Details</Text>
                  <AntDesign name='arrowright' size={15} color={'white'}/>
                </TouchableOpacity>


              </Animated.View>   


          </Animated.View>

          </ScrollView>

    </Animated.View> */}

    <TabNav/>


      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
