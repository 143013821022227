import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import * as ImagePicker from 'expo-image-picker';
import { storage } from '../../../config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {Picker} from '@react-native-picker/picker';

const {width, height} = Dimensions.get('screen');


const NewCampaign = () => {


    const navigation = useNavigation();

    const [campaignName, setCampaignName] = useState('')
    const [campaignDescription, setCampaignDescription] = useState('');

    const [imageone, setImageOne] = useState();
    const [imgUrlOne, setImgUrlOne] = useState();

    const [minPrice, setMinPrice] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('South Africa');
    const [selectedCategory, setSelectedCategory] = useState();

    const [freeEvent, setFreeEvent] = useState(false)

    const mainAllY = useRef(new Animated.Value(30)).current

    const theorange = '#d14324';
    const theblue = '#226dbf'


    const pickImageOne = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
        // console.log(result);
        uploadImageOne(result)
    
        if (!result.canceled) {
          setImageOne(result?.uri);
        }
      };



      const uploadImageOne = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

            // console.log(pickerResult.uri)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrlOne(downloadURL)
            console.log('d')
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }


    const createCampaign = async() => {

        const userId = await Auth.currentAuthenticatedUser()


        const campaignInput = {
            name : campaignName,
            description: campaignDescription,
            image: imgUrlOne,
            ownerId: userId.attributes.sub,
            country: selectedCountry,
            declined: false,
            genre: selectedCategory,
            minAmount: minPrice
        }

        try {
            await API.graphql(graphqlOperation(mutations.createCampaign, { input: campaignInput})).then((ee)=> {
              navigation.goBack()
            })
          } catch (error) {
            console.error(error.errors)
          }

    }

    useEffect(() => {
        
        Animated.parallel([
           
            Animated.timing(mainAllY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, []);


    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
            <View style={{height: '8%', width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Add New Campaign</Text>
                </Animated.View>
                
            </View>

            <ScrollView showsVerticalScrollIndicator={false}>

            
            
        
            <Animated.View style={{backgroundColor: '#fcfcfc', width: width, padding: 30, marginTop: 5, transform: [{translateY: mainAllY}]}}>

                <View style={{borderWidth: 0, padding: 5, alignItems: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', marginBottom: 15}}>Information</Text>
                </View>

                <TouchableOpacity onPress={()=> pickImageOne()} style={{marginTop: 15}}>
                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Cover Image</Text>
                    <ImageBackground  source={{uri: imageone}} style={{height: height / 3, width: width - 50, borderWidth: 1, borderRadius: 20, marginTop: 0, alignItems: 'center', justifyContent: 'center', borderStyle: 'dashed'}}>
                        <View style={{height: 60, width: 60, backgroundColor: '#f2f2f2', borderRadius: 50, alignItems: 'center', justifyContent:'center'}}>
                            <Feather  name='image' size={20} /> 
                        </View>
                    </ImageBackground>
                </TouchableOpacity>

                <View>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10, marginTop: 25}}>Campaign Name</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                        <TextInput placeholder="Blue Summer Wave" placeholderTextColor={'gray'} value={campaignName} onChangeText={setCampaignName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
                </View>

                <View>
                    <View style={{flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 25, justifyContent: 'space-between'}}>
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>Minimum Price (ZAR)</Text>

                        <TouchableOpacity onPress={()=> {setFreeEvent(!freeEvent), setMinPrice(0)}} style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={{fontSize: 10, marginRight: 5}}>Free Event</Text>

                            <View style={{height: 10, width: 10, borderWidth: 0.5, alignItems: 'center', justifyContent: 'center'}}>
                                { freeEvent &&
                                    <View style={{height: 5, width: 5, backgroundColor: 'blue'}} />
                                }
                            </View>
                        </TouchableOpacity>
                        
                    </View>

                    {freeEvent ? 
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                        <TextInput placeholder="0" editable={false} placeholderTextColor={'gray'} value={minPrice} onChangeText={setMinPrice} keyboardType='number-pad' style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
                        :
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                        <TextInput placeholder="Enter Price" placeholderTextColor={'gray'} value={minPrice} onChangeText={setMinPrice} keyboardType='number-pad' style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>

                }
                    
                    



                </View>

                <View>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10, marginTop: 25}}>Country</Text>
                   
                    <Picker
                        selectedValue={selectedCountry}
                        onValueChange={(itemValue, itemIndex) =>
                            setSelectedCountry(itemValue)
                        }>
                        <Picker.Item label="South Africa" value="South Africa" />
                        <Picker.Item label="Eswatini" value="Eswatini" />
                        </Picker>
                </View>


                <View>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10, marginTop: 25}}>Category</Text>
                    <Picker
                        selectedValue={selectedCategory}
                        onValueChange={(itemValue, itemIndex) =>
                            setSelectedCategory(itemValue)
                        }>
                        <Picker.Item label="Music" value="Music" />
                        <Picker.Item label="Comedy" value="Comedy" />
                        <Picker.Item label="Club" value="Club" />
                        <Picker.Item label="Festival" value="Festival" />
                        <Picker.Item label="Church" value="Church" />
                        <Picker.Item label="Restuarant" value="Restuarant" />
                        <Picker.Item label="Rugby" value="Rugby" />
                        <Picker.Item label="Soccer" value="Soccer" />
                        <Picker.Item label="Kids" value="Kids" />
                        <Picker.Item label="Lifestyle" value="Lifestyle" />
                        <Picker.Item label="Bus" value="Bus" />
                        <Picker.Item label="Theatre" value="Theatre" />
                        <Picker.Item label="Tourism" value="Tourism" />
                        <Picker.Item label="Faith" value="Faith" />
                        <Picker.Item label="Seminar" value="Seminar" />
                        <Picker.Item label="Workshop" value="Workshop" />
                        <Picker.Item label="Charity" value="Charity" />
                        <Picker.Item label="Community" value="Community" />
                        <Picker.Item label="Health" value="Health" />
                        </Picker>
                </View>

                <View style={{marginTop: 15}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Description</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 150, paddingLeft: 15, width: width - 50}}>
                        <TextInput  placeholder="A description of the event that will give users more details and booking options" value={campaignDescription} onChangeText={setCampaignDescription} placeholderTextColor={'gray'} multiline={true} style={{fontSize: 12, height: '100%', width: '100%', alignItems: 'flex-start'}}/>
                    </View>
                </View>

               


                <View style={{backgroundColor: '#fcfcfc', width: width, padding: 0, marginTop: 25, }}>
                <TouchableOpacity 
                onPress={()=> createCampaign()}
                style={{height: 45, width: width - 90,  alignItems: 'center', justifyContent: 'center', backgroundColor: theblue, borderRadius: 20, alignSelf: 'center', marginLeft: -50}}>
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Campaign</Text>
                </TouchableOpacity>
            </View>




            </Animated.View>




            </ScrollView>



        </View>
    )

}
export default NewCampaign