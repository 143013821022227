import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput, FlatList, ActivityIndicator } from "react-native";
import React, {useState, useEffect, Fragment, useRef, useCallback, useMemo} from 'react';
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native'; 
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Auth, graphqlOperation, API } from "aws-amplify";
import { Calendar, CalendarUtils } from 'react-native-calendars';
import { getTicket } from "../../graphql/queries";

const {width, height} = Dimensions.get('screen');



const TicketDetails = ({name}) => {


    const navigation = useNavigation();

    const theorange = '#d14324';
    const theblue = '#226dbf'
    

    const [allQueries, setQueries] = useState([]);
    const [eventName, setEventName] = useState('');
    const [city, setCity] = useState();
    const [quantity, setQuantity] = useState();
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [Headline, setHeadline] = useState('');
    const [timee, setTimee] = useState('');

    

    const [spinning, setSpinning] = useState(false)
    const [spinningSuspend, setSpinningSuspend] = useState(false)

    const todayDate = new Date;

    const [selected, setSelected] = useState(INITIAL_DATE);
        const [currentMonth, setCurrentMonth] = useState(INITIAL_DATE);
        const [date, setDate] = useState(new Date(todayDate));
        const [datea, setDatea] = useState('')

        const [toOpt, setToOpt] = useState()

    const AddNewEventMain = useRef(new Animated.Value(width)).current;
    const AddNewEventMainOpacity = useRef(new Animated.Value(0)).current;
    const AddNewEventSmall = useRef(new Animated.Value(width)).current;


    const deleteMainCover = useRef(new Animated.Value(-height)).current;
    const deleteMainOpac = useRef(new Animated.Value(0)).current;

    const deleteButton = useRef(new Animated.Value(-height)).current;

    const INITIAL_DATE = '2023-03-10';






    const animNewEvent = () => {
        Animated.sequence([
                Animated.spring(AddNewEventMain, {
                    toValue: 0,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventMainOpacity, {
                    toValue: 1,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventSmall, {
                    toValue: 0,
                    useNativeDriver: true
                }),
            ]).start()
    }

    const animCloseNewEvent = () => {
        Animated.sequence([
            Animated.spring(AddNewEventSmall, {
                toValue: width,
                useNativeDriver: true
            }), 
            Animated.spring(AddNewEventMainOpacity, {
                toValue: 0,
                useNativeDriver: true
            }),
             
            
            Animated.spring(AddNewEventMain, {
                    toValue: width,
                    useNativeDriver: true
                }),
               
            ]).start()
    }


    const [eventss, setEventss] = useState()


    const listingEvents = async() => {

            await API.graphql(graphqlOperation(getTicket, { id: name})).then((ef)=> {
                const userd = ef.data.getTicket
        
                setEventss(userd)

            }).catch((ee)=> {
                console.error(ee)
              })
            
         
    }


    useEffect(() => {
        listingEvents()
    }, [])



    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current

    const onTabPress = React.useCallback(tabIndex => {
    flatListRef?.current?.scrollToOffset({
        offset: tabIndex * width
            })
        })


       
     

        const createEvent = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            const eventInput = {
                name : eventName,
                eventId: name,
                amount: city,
                description: description,
                qty: quantity,
                content: 'null'
            }
    
    
            try {
                await API.graphql(graphqlOperation(mutations.createTicket, { input: eventInput})).then((ee)=> {
                    animCloseNewEvent()
                })
              } catch (error) {
                console.error(error)
              }
    
        }


        const updateTicket = async()=> {

            setSpinning(true)

            const eventInput = {
                id: name,
                description: description,
            }

            try {
                await API.graphql(graphqlOperation(mutations.updateTicket, { input: eventInput})).then((ee)=> {
                    navigation.goBack()
                    setSpinning(false)
                })
              } catch (error) {
                console.error(error),
                setSpinning(false)
              }


        }


        const updateSuspendTicket = async()=> {

            setSpinningSuspend(true)

            const eventInput = {
                id: name,
                active: !eventss?.active,
            }

            try {
                await API.graphql(graphqlOperation(mutations.updateTicket, { input: eventInput})).then((ee)=> {
                    navigation.goBack()
                    setSpinningSuspend(false)
                })
              } catch (error) {
                console.error(error),
                setSpinningSuspend(false)
              }


        }


    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', paddingTop: 0}}>

            <Animated.View style={{height: height, width: width, backgroundColor: 'rgba(0,0,0,0.7)', opacity: AddNewEventMainOpacity, position: 'absolute', zIndex: 6000, transform: [{translateX: AddNewEventMain}]}}>
                    
                    <Animated.View style={{ width: width - 0, backgroundColor: '#fcfcfc', borderRadius: 0, alignSelf: 'center', marginTop: 0, paddingTop: 15, transform: [{translateX: AddNewEventSmall}]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Animated.View style={{ borderRadius: 50, width: 30, height: 30, backgroundColor: 'black', alignItems: 'center', justifyContent:'center', left: 15, top: 0}}>
                            <TouchableOpacity onPress={()=> animCloseNewEvent()}>
                                    <Ionicons name='arrow-back' size={15} color={'#fcfcfc'} />
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View>
                                <Text style={{marginLeft: 25, fontWeight: 'bold', fontSize: 16}}>Add New Ticket</Text>
                            </Animated.View>
                        </View>
                        
                        <View style={{}}>
                            <ScrollView style={{width: '100%', backgroundColor: 'transparent', marginTop: 10, paddingLeft: 25}}>
                            
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Name</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="General" placeholderTextColor={'gray'}  value={eventName} onChangeText={setEventName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Amount - ZAR</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Amount" placeholderTextColor={'gray'} keyboardType='number-pad' value={city} onChangeText={setCity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Quantity</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Quantity" placeholderTextColor={'gray'} keyboardType='number-pad' value={quantity} onChangeText={setQuantity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                         
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Ticket Description</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Ticket Description" placeholderTextColor={'gray'} value={description} onChangeText={setDescription} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                                               


                            <View style={{marginTop: 25, marginBottom: 25, alignSelf: 'center'}}>
                                <TouchableOpacity onPress={()=> createEvent()} style={{height: 45, width: width - 90,  alignItems: 'center', justifyContent: 'center', backgroundColor: theblue, borderRadius: 20}}>
                                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Ticket</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{marginBottom: 100}} />


                            </ScrollView>
                        </View>


                    </Animated.View>
            </Animated.View>

            
            {/* {emptyItems()} */}

            <View style={{width: '100%', height: '100%', paddingTop: 25, alignItems: 'center'}}>


            <ScrollView style={{width: '100%', backgroundColor: 'transparent', marginTop: 0, paddingLeft: 25}}>


                <View style={{marginTop: 25}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Price</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.2)', borderColor: 'rgba(34, 109, 191, 0.1)', borderWidth: 1, borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                        <Text editable={false} placeholderTextColor={'gray'}style={{fontSize: 16, fontWeight: 'bold', alignItems: 'center'}}>ZAR{Number(eventss?.amount).toFixed(2)}</Text>
                    </View>
                </View>   


                <View style={{marginTop: 25}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Quantity</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.2)', borderColor: 'rgba(34, 109, 191, 0.1)', borderWidth: 1, borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                        <Text editable={false} placeholderTextColor={'gray'}  style={{fontSize: 16, fontWeight: 'bold', alignItems: 'center'}}>{Number(eventss?.qty)}</Text>
                    </View>
                </View>  

                <View style={{marginTop: 25}}>
                    <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Description</Text>
                    <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.2)', borderColor: 'rgba(34, 109, 191, 0.1)', borderWidth: 1, borderRadius: 20, height: 120, paddingLeft: 15, width: width - 50}}>
                        <TextInput placeholder="Description" placeholderTextColor={'gray'} multiline value={description || eventss?.description} onChangeText={setDescription}  style={{fontSize: 12, height: '100%', width: '100%'}}/>
                    </View>
                </View>    

           
                
                <Animated.View style={{marginTop: 25}}>

                <TouchableOpacity onPress={()=> updateSuspendTicket()} style={{height: 45, width: width - 50, backgroundColor: 'transparent', borderWidth: 0, borderColor: theblue, borderRadius: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 15}}>
                       
                       <View style={{position: 'absolute', left: 15}}>
                           {spinningSuspend ? <ActivityIndicator size={'small'} color={'#fcfcfc'} /> : <View/> }
                       </View>
                      
                      <Text style={{color: theblue, fontWeight: 'bold', marginRight: 10}}> {eventss?.active ? `Suspend Ticket` : `Activate Ticket`}</Text>
                       {/* <Feather name= 'save'  color={'#fcfcfc'} size={18}  /> */}
                   </TouchableOpacity>



                    <TouchableOpacity onPress={()=> updateTicket()} style={{height: 45, width: width - 50, backgroundColor: theblue, borderRadius: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                       
                        <View style={{position: 'absolute', left: 15}}>
                            {spinning ? <ActivityIndicator size={'small'} color={'#fcfcfc'} /> : <View/> }
                        </View>
                       
                       <Text style={{color: '#fcfcfc', fontWeight: 'bold', marginRight: 10}}>Update</Text>
                        <Feather name= 'arrow-up-right'  color={'#fcfcfc'} size={18}  />
                    </TouchableOpacity>
                </Animated.View>


                <View style={{marginBottom: height / 2}} />

            </ScrollView>

                



                
            </View>
{/* 
            <TouchableOpacity onPress={()=> animNewEvent()} style={{height: 40, paddingHorizontal: 10, flexDirection: 'row', marginLeft: 10, backgroundColor: theblue, alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center', position: 'absolute', zIndex: 5000, right: 20, bottom: height / 3 + 50}}>
                    <Feather name='plus'  color={'#fcfcfc'} size={18}  />
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Create Ticket</Text>
                </TouchableOpacity>       */}


        </View>
    )

}
export default TicketDetails