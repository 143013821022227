import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity, TextInput, ScrollView, FlatList, ActivityIndicator} from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { getUserData } from "../../graphql/queries";
import {Picker} from '@react-native-picker/picker';



const {width, height} = Dimensions.get('screen')

const BankingDetails = () => {

    const navigation = useNavigation();

    const [userInfo, setUserInfo] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const [withAmount, setWithAmount] = useState('');
    // const [bankDet, setBankDet] = useState({
    //   name: '',
    //   branch: '',
    //   swift: '',
    //   country: ''
    // })

    const [showingBanks, setShowingBanks] = useState(false);
    const [accountName, setAccountName] = useState('');
    const [accNumber, setAccountNumber] = useState('');
    const [spinning, setSpinning] = useState(false);



    const bankList = [
      {
        id: 1, 
        name: "ABSA LIMITED",
        branch: 444444,
        swift: 'ABSAZA',
        country: 'South Africa'
      },
      {
        id: 2, 
        name: "CAPITEC",
        branch: 444444,
        swift: 'CAPZA',
        country: 'South Africa'
      },
      {
        id: 3, 
        name: "DISCOVERY BANK",
        branch: 444444,
        swift: 'CAPZA',
        country: 'South Africa'
      },
      {
        id: 4, 
        name: "FNB SOUTH AFRICA",
        branch: 6506500,
        swift: 'CAPZA',
        country: 'South Africa'
      },
      {
        id: 5, 
        name: "FNB ESWATINI",
        branch: 280164,
        swift: 'CAPZA',
        country: 'Eswatini'
      },
      {
        id: 6, 
        name: "LOOMI AFRICA",
        branch: 9985545,
        swift: 'LOOMAF',
        country: 'South Africa'
      },
      {
        id: 7, 
        name: "MUKURU WORLD WIDE",
        branch: 9985545,
        swift: 'CAPZA',
        country: 'South Africa'
      },

    ]
 
    const WalletMainY = useRef(new Animated.Value(100)).current;  
    const eventsMainY = useRef(new Animated.Value(60)).current;   


    const theorange = '#d14324';
    const theblue = '#226dbf';

    const getUserInfo = async()=>{
  
      const userAuthenticated =  await Auth.currentAuthenticatedUser();
      const userSub = userAuthenticated.attributes.sub;
  
  
    try{
          await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
          const userd = ef.data.getUserData
          
  
          setUserInfo(userd)

          // console.log('SS', userd)
  
        }).catch((ee)=> {
          console.error(ee)
        })
  
    } catch (e){
      console.error(e)
    }

    }


    

useEffect(() => {
  getUserInfo()

  Animated.parallel([
    Animated.timing(WalletMainY, {
        toValue: 0,
        useNativeDriver: true
    }),
    Animated.timing(eventsMainY, {
        toValue: 0,
        useNativeDriver: true
    }),
]).start()

  // if(withAmount > availBal){
  //   setErrorMsg('Insufficient Amount')
  // } else {
  //   setErrorMsg('')
  // }
  
  }, [userInfo])

  const [bankDet, setBankDet] = useState({
    name: userInfo?.bank || '',
    branch: '',
    swift: '',
    country: ''
  })

  
  let availBal = (userInfo?.balance - userInfo?.balance * 0.03)


  const withdrawFunds = async()=> {

    if(withAmount > availBal){
      setErrorMsg('Insufficient Amount')
      return
    }

    if(!userInfo?.bank){
      setErrorMsg('Please Add Banking Details')
      return
    }  

    const userId = Auth.currentAuthenticatedUser()

    const inputUser = {
      id: userId.attributes.sub,
      balance: (userInfo?.balance || 0) - Number(withAmount),
      withdrawnAmount: (userInfo?.withdrawnAmount || 0) + Number(withAmount),

    }

    const inputTransaction = {
      userId: userId.attributes.sub,
      amount: Number(withAmount),
      inOrOut: 'Withdrawal',
    }

    try {
     await API.graphql(graphqlOperation(mutations.updateUserData, {input: inputUser})).then((e)=> {
      API.graphql(graphqlOperation(mutations.createTransactions, {input: inputTransaction})).then((eff)=> {
        navigation.goBack()
      })
     })
    } catch (error) {
      console.error(error)
    }    




  }


 const renderBanks = ({item}) => {
    return(
      <TouchableOpacity 
        onPress={()=> {
          setBankDet({
            name: item?.name,
            branch: item.branch,
            swiftCode: item?.swift,
            country: item?.country
          }), setShowingBanks(false)
        }}

        style={{height: 45}}
      >
        <Text style={{fontSize: 12}}>{item.name}</Text>
      </TouchableOpacity>
    )
  }

  const updateBankDetails = async() =>  {

    setSpinning(true)

    const userId = await Auth.currentAuthenticatedUser()

    const inputUser = {
      id: userId.attributes.sub,
      bank: bankDet.name,
      branchNumber: bankDet.branch,
      accountNumber: accNumber,
      bankCountry: bankDet.country,
      SwiftNumber: bankDet.swift
    }


    try {
      await API.graphql(graphqlOperation(mutations.updateUserData, {input: inputUser})).then((e)=> {
        setSpinning(false)
        navigation.goBack()
      })
     } catch (error) {
       console.error(error),
       setSpinning(false)
     }    




  }


  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Banking Details</Text>
                </Animated.View>
                
        </View>

        {showingBanks && 
              <TouchableOpacity onPress={()=> setShowingBanks(false)} style={{position: 'absolute', zIndex: 5000, height: height, width: width, backgroundColor: 'rgba(0,0,0,0.8)', alignItems: 'center', paddingTop: 100}}>
                   
                   <Animated.View style={{  width: (width * 0.9), marginTop: 15, padding: 25, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, marginHorizontal: 10}}>

                    <View style={{height: 50, justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'wallet' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Select Your Bank</Text>
                    </View>
                  
                    </View>

                    <FlatList 
                      data={bankList}
                      renderItem={renderBanks}
                      style={{padding: 15}}
                    />

                   </Animated.View> 
              </TouchableOpacity>
            }

        <ScrollView>
          
           
        
            <Animated.View style={{alignItems: 'center', marginTop: 15, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, marginHorizontal: 10, transform: [{translateY: eventsMainY}]}}>

                <View style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'wallet' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Enter your banking details</Text>
                    </View>
                  
                </View>

              
                        <View style={{marginTop: 20, marginBottom: 30, alignItems: 'center', width: '100%'}}>

                            <View>
                            <Text style={{fontSize: 12, marginBottom: 10}}>Bank</Text>
                            <TouchableOpacity onPress={()=> setShowingBanks(true)}  style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                              <TextInput style={{height: 50, width: '100%'}} editable={false} value={bankDet.name || userInfo?.bank || `Select Bank`} keyboardType='number-pad' />

                              <View style={{position: 'absolute', right: 15}}>
                                <AntDesign name='caretdown' size={10} color={'gray'} />
                              </View>
                            </TouchableOpacity>
                            </View>

                            <View style={{marginTop: 20}}>
                            <Text style={{fontSize: 12, marginBottom: 10}}>Account Holder</Text>
                            <View style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                              <TextInput style={{height: 50, width: '100%'}} placeholder= {'Account Holder'} onChangeText={setAccountName} value={accountName} />
                            </View>
                            </View>

                            <View style={{marginTop: 20}}>
                            <Text style={{fontSize: 12, marginBottom: 10}}>Branch Code</Text>
                            <View style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                            <TextInput style={{height: 50, width: '100%'}} editable={false} value={String(userInfo?.branchNumber) || String(bankDet?.branch) ||  `--`} keyboardType='number-pad' />

                            </View>
                            </View>

                            <View style={{marginTop: 20}}>
                            <Text style={{fontSize: 12, marginBottom: 10}}>Account Number</Text>
                            <View style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                              <TextInput style={{height: 50, width: '100%'}} placeholder= {String(userInfo?.accountNumber) || 'Account Number'} onChangeText={setAccountNumber} value={String(accNumber) || userInfo?.accountNumber} keyboardType='number-pad' />
                            </View>
                            </View>

                            <View style={{marginTop: 20}}>
                            <Text style={{fontSize: 12, marginBottom: 10}}>Country</Text>
                            <View style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                              <TextInput style={{height: 50, width: '100%'}} editable={false} value={String(bankDet.country) || userInfo?.bankCountry || `--`} />
                            </View>
                            </View>
                            

                            {errorMsg && 
                            <Text style={{fontSize: 11, color: 'red'}}>{errorMsg}</Text>
                            }

                            {!accNumber ? 
                              <View style={{height: 45, width: '80%', backgroundColor: theblue, opacity: 0.65, marginTop: 15,  borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 0}}>Enter Details</Text>
                              </View> :

                            <TouchableOpacity onPress={()=> updateBankDetails()} style={{height: 45, width: '80%', backgroundColor: theblue, marginTop: 15,  borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                
                                {spinning && 
                                  <View style={{position: 'absolute', left: 15}}>
                                      <ActivityIndicator size={'small'} color={'#fcfcfc'} />
                                  </View>
                                }
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 0}}>Update Details</Text>
                            </TouchableOpacity>
                          }
                            


                        </View>
                     
                    
               





            </Animated.View>

        
            </ScrollView>
    </View>
  )
}

export default BankingDetails

