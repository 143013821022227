import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Button, Image, ImageBackground, ScrollView, TextInput, FlatList } from "react-native";
import React, {useState, useEffect, Fragment, useRef, useCallback, useMemo} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import LottieView from 'lottie-react-native'; 
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { Auth, graphqlOperation, API } from "aws-amplify";
import { FlashList } from "@shopify/flash-list";
import { Calendar, CalendarUtils } from 'react-native-calendars';
import DateTimePicker from '@react-native-community/datetimepicker';
import { DateTimePickerAndroid } from "@react-native-community/datetimepicker";




const {width, height} = Dimensions.get('screen');

const mainColour = 'purple'

const HeaderList = [
     
    {
        id: 0,
        item: 'Active'
    },
    {
        id: 1,
        item: 'Pending'
    }
    ,
    {
        id: 2,
        item: 'Past'
    },
    {
        id: 3,
        item: 'Other'
    },
           

]

const HeadingLis = HeaderList.map((one)=> ({
    ...one,
    ref: React.createRef()
}))

const TabIndicator = ({measureLayout, scrollX}) => {

    

    const inputRange = HeadingLis.map((_,i)=> i*width)

    const tabIndicatorWidth = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.width)
    });

    const translateX = scrollX.interpolate({
        inputRange,
        outputRange : measureLayout.map(measure => measure.x)
    })
    

    return(
        <Animated.View 
        style={{position: 'absolute', bottom: 0, height: 3, 
        width: tabIndicatorWidth, borderRadius: 2, backgroundColor: mainColour,
        transform: [{
            translateX
        }]
        }}
        />
    )
}

const Tabio = ({scrollX, onTabPress}) => {

    const [measureLayout, setMeasureLayout] = useState([])
    const containerRef = useRef();

    useEffect(() => {
      let ml = []

      HeadingLis.forEach(two => {
        two?.ref?.current?.measureLayout(
            containerRef.current,
            (x, y, width, height) => {
                ml.push({
                    x, y, width, height
                })

                if(ml.length === HeadingLis.length){
                    setMeasureLayout(ml)
                }
            }
        )
      })
    }, [containerRef.current])
    
    


    return(
        <View
            ref={containerRef}
            style={{flex:1, flexDirection: 'row', }}
        >
           

            {/* <TabIndicator/> */}

            {measureLayout.length > 0 && <TabIndicator measureLayout={measureLayout} scrollX={scrollX} />}

            {/* Tabs */}
            {HeadingLis.map((item, index) => {
                return(
                    <TouchableOpacity 
                        key={`Tab-${index}`} 
                        ref={item.ref}
                        style={{flex: 1,
                        paddingHorizontal: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                        }}
                        onPress={()=> {
                            onTabPress(index)
                        }}
                    >
                        <Text style={{fontSize: 12, fontWeight: 'bold'}}>{item?.item}</Text>
                    </TouchableOpacity>
                )
            })}
        </View>
    )
}





const EventScreen = ({name}) => {


    const navigation = useNavigation();

    const theorange = '#d14324';
    const theblue = '#226dbf'
    

    const [allQueries, setQueries] = useState([]);
    const [eventName, setEventName] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [description, setDescription] = useState('');
    const [Headline, setHeadline] = useState('');
    const [timee, setTimee] = useState('');
    const [newTime, setNewTime] = useState('');
    const [newAnkole, setNewAnkole] = useState('');

    const todayDate = new Date;

    const [selected, setSelected] = useState(INITIAL_DATE);
        const [currentMonth, setCurrentMonth] = useState(INITIAL_DATE);
        const [date, setDate] = useState(new Date(todayDate));
        const [dateb, setDateb] = useState(new Date(todayDate));
        const [datea, setDatea] = useState('')


        const onChange = (event, selectedDate) => {
            const currentDate = selectedDate;
            setNewAnkole(String(currentDate.toLocaleTimeString('it-IT')))   
            setNewTime(currentDate);
          };

    const AddNewEventMain = useRef(new Animated.Value(width)).current;
    const AddNewEventMainOpacity = useRef(new Animated.Value(0)).current;
    const AddNewEventSmall = useRef(new Animated.Value(width)).current;

    const INITIAL_DATE = '2024-02-19';

    const getDate = (count: number) => {
        const date = new Date(INITIAL_DATE);
        const newDate = date.setDate(date.getDate() + count);
        return CalendarUtils.getCalendarDateString(newDate);
      };

      const onDayPress = useCallback((day) => {
        setSelected(day.dateString);
      }, []);

      const renderCalendarWithMinAndMaxDates = () => {
        return (
          <Fragment>
            <Calendar
              style={{width: 300}}
              current={INITIAL_DATE}
              minDate={getDate(-0)}
              maxDate={getDate(200)}
              showSixWeeks={true}
              disableAllTouchEventsForDisabledDays
              onDayPress={(ee)=> {setDate(ee.dateString), console.log(ee.dateString), setDatea(ee.dateString)}}
            />
          </Fragment>
        );
      };



    const animNewEvent = () => {
        Animated.sequence([
                Animated.spring(AddNewEventMain, {
                    toValue: 0,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventMainOpacity, {
                    toValue: 1,
                    useNativeDriver: true
                }),
                Animated.spring(AddNewEventSmall, {
                    toValue: 0,
                    useNativeDriver: true
                }),
            ]).start()
    }

    const animCloseNewEvent = () => {
        Animated.sequence([
            Animated.spring(AddNewEventSmall, {
                toValue: width,
                useNativeDriver: true
            }), 
            Animated.spring(AddNewEventMainOpacity, {
                toValue: 0,
                useNativeDriver: true
            }),
             
            
            Animated.spring(AddNewEventMain, {
                    toValue: width,
                    useNativeDriver: true
                }),
               
            ]).start()
    }


    const [eventss, setEventss] = useState([])


    const listingEvents = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});
        
            const allTodos = await API.graphql({query: queries.listEvents, variables: {
                filter: {
                    campaignId: {eq: name}
                }
            }})
            
            const todos = allTodos.data.listEvents.items
            setEventss(todos)

    }


    useEffect(() => {
        listingEvents()
    }, [])


    const emptyItems = () => {
       if(eventss?.filter(cp => !cp.product).length === 0){
        return  (
            <Animated.View style={{alignItems: 'center', backgroundColor: 'white',  marginTop: 0, flex: 1, padding: 30}}>
                    <Text style={{alignSelf: 'center', fontSize: 16, fontWeight: 'bold', color: '#233f4d', marginTop: 50}}>You have no events</Text>
            </Animated.View>
        )
    } 
    }

 
    const flatListRef = useRef();
    const scrollX = useRef(new Animated.Value(0)).current

    const onTabPress = React.useCallback(tabIndex => {
    flatListRef?.current?.scrollToOffset({
        offset: tabIndex * width
            })
        })


       
        const tabs = ()=>{
            return(
                <View style={{height: 50, width: width, borderBottomWidth: 0.5, borderColor: '#d1d1d1', borderTopWidth: 0.5, backgroundColor: '#fcfcfc'}}>
                    <Tabio scrollX={scrollX} onTabPress={onTabPress}/>    
                </View>
    
            )
        }

        const createEvent = async() => {

            const userId = await Auth.currentAuthenticatedUser()
    
    
            const eventInput = {
                name : eventName,
                ownerId: userId.attributes.sub,
                city: city,
                country: 'South Africa',
                address: address,
                description: description,
                headlineAct: Headline,
                eventDate: datea,
                eventTime: newAnkole,
                campaignId: name,
            }
    
            try {
                await API.graphql(graphqlOperation(mutations.createEvent, { input: eventInput})).then((ee)=> {
                    listingEvents(),
                    animCloseNewEvent()
                })
              } catch (error) {
                console.error(error)
              }
    
        }

        const renderCampaigns = ({item}) => {
            return(
                <TouchableOpacity onPress={()=> navigation.navigate('ManageEvent', {item: item})} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', alignSelf: 'center', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                    
                    <View style={{width: '20%', height: '80%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(227, 238, 250, 0.99)', borderRadius: 10}}>
                        {/* <Image
                            style={{height: 70, width: 70, borderRadius: 10}}
                            source={{uri: item?.image}}
                        /> */}
                        <Feather name='calendar' size={40} color='gray'/>
                    </View>
    
                    <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 15}}>{item.city || item?.name}</Text>  
                      <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 100)}...</Text>
                    </View>
    
                    <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                        <Feather name='arrow-right' size={20}/>
                    </View>
                    
                </TouchableOpacity>
            )
          }

          const showMode = (currentMode) => {
            DateTimePickerAndroid.open({
              value: dateb,
              onChange,
              mode: currentMode,
              is24Hour: true,
            });
          };
        

          const showTimepicker = () => {
            showMode('time');
          };

    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc', paddingTop: 0}}>

            <Animated.View style={{height: height, width: width, backgroundColor: 'rgba(0,0,0,0.7)', opacity: AddNewEventMainOpacity, position: 'absolute', zIndex: 6000, transform: [{translateX: AddNewEventMain}]}}>
                    
                    <Animated.View style={{ width: width - 0, backgroundColor: '#fcfcfc', borderRadius: 0, alignSelf: 'center', marginTop: 0, paddingTop: 15, transform: [{translateX: AddNewEventSmall}]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Animated.View style={{ borderRadius: 50, width: 30, height: 30, backgroundColor: 'black', alignItems: 'center', justifyContent:'center', left: 15, top: 0}}>
                            <TouchableOpacity onPress={()=> animCloseNewEvent()}>
                                    <Ionicons name='arrow-back' size={15} color={'#fcfcfc'} />
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View>
                                <Text style={{marginLeft: 25, fontWeight: 'bold', fontSize: 16}}>Add New Event</Text>
                            </Animated.View>
                        </View>
                        
                        <View style={{}}>
                            <ScrollView style={{width: '100%', height: height /2.2, backgroundColor: 'transparent', marginTop: 10, paddingLeft: 25}}>
                            
                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Event Name</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Blue Summer Wave Pretoria" placeholderTextColor={'gray'} value={eventName} onChangeText={setEventName} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>City</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Pretoria" placeholderTextColor={'gray'} value={city} onChangeText={setCity} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Address</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="45 Comissioner Street, Johannesburg" placeholderTextColor={'gray'} value={address} onChangeText={setAddress} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Short Event Description</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Short Event Description" placeholderTextColor={'gray'} value={description} onChangeText={setDescription} multiline={true} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Headline Act</Text>
                                <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 50}}>
                                    <TextInput placeholder="Zola" placeholderTextColor={'gray'} value={Headline} onChangeText={setHeadline} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                                </View>
                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Event Date </Text>
                                
                                <Fragment>
                  
                                    {renderCalendarWithMinAndMaxDates()}
                                </Fragment>   




                            </View>

                            <View style={{marginTop: 25}}>
                                <Text style={{fontSize: 12, fontWeight: 'bold', marginBottom: 10}}>Time</Text>
                                
                                <Text style={{alignSelf: 'center', fontSize: 12, marginBottom: 10, color: theblue}}>{newAnkole}</Text>
                                
                                <TouchableOpacity onPress={()=> showTimepicker()} style={{height: 45, width: width - 90, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderColor: theblue, backgroundColor: 'transparent', borderRadius: 20}}>
                                    <Text style={{color: theblue, fontWeight: 'bold'}}>Set Event Time</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{marginTop: 50, marginBottom: 100}}>
                                <TouchableOpacity onPress={()=> createEvent()} style={{height: 45, width: width - 90, alignSelf: 'center',  alignItems: 'center', justifyContent: 'center', backgroundColor: theblue, borderRadius: 20}}>
                                    <Text style={{color: '#fcfcfc', fontWeight: 'bold'}}>Create Event</Text>
                                </TouchableOpacity>
                            </View>

                           <View style={{marginBottom: height / 2}} />

                            </ScrollView>
                        </View>


                    </Animated.View>
            </Animated.View>



            

            <View style={{width: '100%', height: '100%', paddingTop: 15, alignItems: 'center', backgroundColor: '#fcfcfc'}}>

            <TouchableOpacity onPress={()=> animNewEvent()} style={{height: 40, paddingHorizontal: 10, marginBottom: 20, flexDirection: 'row', marginLeft: 10, backgroundColor: theblue, alignItems: 'center', borderWidth: 0, borderColor: 'red', borderRadius: 20, justifyContent: 'center'}}>
                    <Feather name='plus'  color={'#fcfcfc'} size={18}  />
                    <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 12, marginLeft: 5}}>Create Event</Text>
                </TouchableOpacity>            

           
            
            {emptyItems()}


                <FlatList 
                    data={eventss}
                    renderItem={renderCampaigns}
                />
            </View>




        </View>
    )

}
export default EventScreen