import React, {useState} from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'; 
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {Text, View, SafeAreaView, TextInput} from 'react-native';
import { Feather } from '@expo/vector-icons';
import HomeScreen from '../screens/HomeScreen';
import MenuScreen from '../screens/MenuScreens/MenuScreen';
import Campaigns from '../screens/CamapignsScreens/Campaigns';
import ManageCampaign from '../screens/CamapignsScreens/ManageCampaign';
import TicketEvent from '../screens/CamapignsScreens/TicketEvent';
import EventScreen from '../screens/CamapignsScreens/EventScreen';
import ManageEvent from '../screens/CamapignsScreens/ManageEvents';
import NewCampaign from '../screens/CamapignsScreens/NewCampaign';
import ManageTicket from '../screens/CamapignsScreens/ManageTicket';
import SalesTicketList from '../screens/CamapignsScreens/SalesTicketList';


const Stack = createNativeStackNavigator();


const CampaignStack = () => {


    const EmptyScreen = ()=>{
        return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#e4e6eb'}}>
                <View style={{height: 150, width: 150, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', borderRadius: 20}}>
                    <Text>Empty Screenss</Text>
                </View>
            </View>
        )
    }

    return (
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
                <Stack.Screen name="CampaignsMain">
                    {() => <Campaigns />}
                </Stack.Screen>

                 <Stack.Screen component={ManageCampaign} name="ManageCampaign" options={{headerShown: false}} />
                 <Stack.Screen component={TicketEvent} name="TicketEvent" options={{headerShown: false}} />
                 <Stack.Screen component={EventScreen} name="EventScreen" options={{headerShown: false}} />
                 <Stack.Screen component={ManageEvent} name="ManageEvent" options={{headerShown: false}} />
                 <Stack.Screen component={NewCampaign} name="NewCampaign" options={{headerShown: false}} />
                 <Stack.Screen component={ManageTicket} name="ManageTicket" options={{headerShown: false}} />
                 <Stack.Screen component={SalesTicketList} name="SalesTicketList" options={{headerShown: false}} />
            </Stack.Navigator>
      
    );
};



export default CampaignStack;
