import { View, Text, TouchableOpacity, Dimensions, Image, FlatList } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from "@react-navigation/native";
import * as queries from '../../graphql/queries';
import { Auth, API } from "aws-amplify";

const {width, height} = Dimensions.get('screen');





const PastList = () => {


    const navigation = useNavigation();
    const [campaigns, setCampaigns] = useState([])

    const deepBlueColour = '#052242'

    const listingMessageRooms = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});
        

            const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
                {
                    filter : { and: [ 
                        { ownerId: {eq: (userplus?.attributes.sub)}},
                        {active:    {eq: false}}
                    ]}
                } });
            
            const todos = allTodos.data.listCampaigns.items
            setCampaigns(todos)

            // console.log(todos)

    }

    useEffect(() => {
        listingMessageRooms();
      }, [])
   
      const renderCampaigns = ({item}) => {
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('ManageCampaign', {item: item})} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                
                <View style={{width: '20%'}}>
                    <Image
                        style={{height: 70, width: 70, borderRadius: 10}}
                        source={{uri: item?.image}}
                    />
                </View>

                <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                  <Text style={{fontWeight: 'bold', fontSize: 15, color: deepBlueColour}}>{item.name}</Text>  
                  <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.description).slice(0, 200)}</Text>
                </View>

                <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                    <Feather name='arrow-right' size={20}/>
                </View>
                
            </TouchableOpacity>
        )
      }

    return(
        <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
                   
            <View style={{backgroundColor: '#fcfcfc', width: width, marginTop: 0, padding: 15, marginBottom: 100}}>

                <View style={{width: '100%', paddingTop: 15}}>

                    <FlatList 
                        data={campaigns}
                        renderItem={renderCampaigns}
                        style={{height: height / 2.3, backgroundColor: '#fcfcfc'}}
                    />
                    </View>
            </View>

        </View>
    )

}
export default PastList