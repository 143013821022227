import React, {useState} from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'; 
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {Text, View, SafeAreaView, TextInput} from 'react-native';
import { Feather } from '@expo/vector-icons';
import HomeScreen from '../screens/HomeScreen';
import MenuScreen from '../screens/MenuScreens/MenuScreen';


const Stack = createNativeStackNavigator();


const MenuStack = () => {


    const EmptyScreen = ()=>{
        return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#e4e6eb'}}>
                <View style={{height: 150, width: 150, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', borderRadius: 20}}>
                    <Text>Empty Screenss</Text>
                </View>
            </View>
        )
    }

    return (
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
                <Stack.Screen name="Menu">
                    {() => <MenuScreen />}
                </Stack.Screen>

                 <Stack.Screen component={MenuScreen} name="Category" options={{headerShown: false}} />
       
            </Stack.Navigator>
      
    );
};



export default MenuStack;
