import React, {useState} from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'; 
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {Text, View, SafeAreaView, TextInput} from 'react-native';
import { Feather } from '@expo/vector-icons';
import HomeScreen from '../screens/HomeScreen';
import CashOut from '../screens/HomeScreens/CashOut';
import BankingDetails from '../screens/HomeScreens/BankingDetails';

const Stack = createNativeStackNavigator();


const HomeStack = () => {


    const EmptyScreen = ()=>{
        return(
            <View style={{alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#e4e6eb'}}>
                <View style={{height: 150, width: 150, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', borderRadius: 20}}>
                    <Text>Empty Screenss</Text>
                </View>
            </View>
        )
    }

    return (
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                }}
            >
                <Stack.Screen name="HomeScreen">
                    {() => <HomeScreen />}
                </Stack.Screen>

                <Stack.Screen component={CashOut} name="CashOut" options={{headerShown: false}} />
                 <Stack.Screen component={BankingDetails} name="BankingDetails" options={{headerShown: false}} />
               {/* <Stack.Screen component={ProductDetails} name="ProdDet" options={{headerShown: false}} />
                <Stack.Screen component={NotificationMain} name="Notifications" options={{headerShown: false}} />
                <Stack.Screen component={MessageMain} name="Messages" options={{headerShown: false}} />
                <Stack.Screen component={MessageDetails} name="MessageDetails" options={{headerShown: false}} />
                <Stack.Screen component={LearnMore} name="LearnMore" options={{headerShown: false}} />
                <Stack.Screen component={ListActive} name="ListActive" options={{headerShown: false}} />
                <Stack.Screen component={TrackOrder} name="TrackOrder" options={{headerShown: false}} />
                <Stack.Screen component={ChatScreen} name="ChatScreen" options={{headerShown: false}} />
                <Stack.Screen component={ProductList} name="ProductList" options={{headerShown: false}} /> */}
            </Stack.Navigator>
      
    );
};



export default HomeStack;
