import { StyleSheet, Text, View, Image, Animated, ImageBackground, TextInput, TouchableOpacity, Dimensions, ScrollView, ActivityIndicator } from 'react-native'
import React, {useRef, useState, useEffect} from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import * as mutations from '../graphql/mutations';
import Tabs from './Tabs';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import {getUserData} from '../graphql/queries'



const {width, height} = Dimensions.get('screen')

const TabNav = () => {

    const [userInfo, setUserInfo] = useState()

    const [emailaddress, changeEmailAddress] = useState('');
    const [emailPassword, changeEmailPassword] = useState('');

    const [IdNumber, ChangeIdNumber] = React.useState('');
    const [IdcountryIssue, ChangeIdcountryIssue] = React.useState('');
    const [businessName, ChangebusinessName] = React.useState('');
    const [businessAddress, ChangebusinessAddress] = React.useState('');
    const [phoneNumber, ChangePhoneNumber] = React.useState('');
    const [firstName, ChangeFirstName] = React.useState('');

    const [spinning, setSpinning] = useState(false)

    const modalx = useRef(new Animated.Value(width)).current;

    const [fnError, setfnError] = useState('')
  const [fnErrorMessage, setfnErrorMessage] = useState('Missing Information')


    const profileImage = 'https://images.unsplash.com/photo-1531727991582-cfd25ce79613?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'


    const getUserThings = async()=>{
  
        const userAuthenticated =  await Auth.currentAuthenticatedUser();
        const userSub = userAuthenticated.attributes.sub;
    
    
      try{
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
            const userd = ef.data.getUserData
    
            setUserInfo(userd)

                if(!userd?.profileComplete){

                    Animated.spring(modalx, {
                        toValue: 1,
                        useNativeDriver: true
                    }).start()

                }else {
                    Animated.spring(modalx, {
                        toValue: width,
                        useNativeDriver: true
                    }).start()
                }

    
          }).catch((ee)=> {
            console.error(ee)
          })
    
      } catch (e){
        console.error(e)
      }
      
    
    
      }
    
    useEffect(() => {
        getUserThings()

    }, [userInfo])


 const mainColour = '#d40404';
 const toyinColour = '#f25268';


 const submitUpdate = async()=> {

    if( !userInfo?.firstName && !firstName){
      setfnError('fn')
      return
    } else if(!userInfo?.IdNumber && !IdNumber){
      setfnError('id')
      return
    }else if(!userInfo?.IdcountryIssue && !IdcountryIssue){
      setfnError('idCountry')
      return
    }else if(!userInfo?.phoneNumber && !phoneNumber){
      setfnError('pn')
      return
    }else if(!userInfo?.businessName && !businessName){
      setfnError('busN')
      return
    }else if(!userInfo?.businessAddress && !businessAddress){
      setfnError('busAdd')
      return
    }


    const userId = await Auth.currentAuthenticatedUser();

    setSpinning(true)


      const userSub = userId.attributes.sub
      
      const inputM = {
        id: userSub,
        email: userId.attributes.email || `Not Found`,
        idNumber: IdNumber || `Not Found`,
        idcountryIssue: IdcountryIssue || `Not Found`,
        phoneNumber: phoneNumber || `Not Found`,
        businessName: businessName || `Not Found`,
        businessAddress: businessAddress || `Not Found`,
        profileComplete: true
  
      }

      const inputVendor = {
        id: userSub,
        email: userId.attributes.email,
        firstName: userInfo?.firstName || firstName,
        idNumber: userInfo?.IdNumber || IdNumber,
        idcountryIssue: IdcountryIssue,
        phoneNumber: userInfo?.phoneNumber || phoneNumber,
        businessName: businessName,
        businessAddress: businessAddress
      }

    try {

      await API.graphql({ query: mutations.updateUserData, variables: {input: inputM}}).then((e)=> {
        API.graphql({ query: mutations.createVendorData, variables: {input: inputVendor}}).then((ee)=> {
          setSpinning(false)
        setSpinning(false)
        })
        
      }).catch((error)=> {
        console.warn(error),
        setSpinning(false)
      }) 

    } catch (error) {
      console.error(error),
      setfnError('fn'),
      setSpinning(false)
    }
  }
 

  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}> 



        <NavigationContainer>
            <Tabs/>
        </NavigationContainer>

      

        <Animated.View style={{height: height, width: width, backgroundColor: '#fcfcfc', position: 'absolute', zIndex: 50000, paddingTop: 15, transform: [{translateX: modalx}]}}>

      <ScrollView showsVerticalScrollIndicator={false}>

          <Animated.View style={{margin: 15}}>
            <Text style={{fontWeight: 'bold', fontSize: 20}}>Missing Information</Text>

            <Text style={{marginTop: 15, textAlign: 'justify', fontSize: 12}}>Looks like theres a bit information we dont have about you. Please enter below to continue</Text>
          </Animated.View>


          <Animated.View style={{margin: 15}}>

                  {!userInfo?.firstName ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>First Name</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='John' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeFirstName} value={firstName} />
              </View>
              

              </View>

              {fnError === 'fn' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Missing Information`}</Text> : <View />} 

              </View>
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.idNumber ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>ID / Passport Number</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='************' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeIdNumber} value={IdNumber} />
              </View>

            </View>
            {fnError === 'id' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
              </View>
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              {!userInfo?.idcountryIssue ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Country Of Issue</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='South Africa' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangeIdcountryIssue} value={IdcountryIssue} />
              </View>

            </View>
                        </View>
                        {fnError === 'idCountry' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.phoneNumber ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Phone Number</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='0662421186' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangePhoneNumber} value={phoneNumber} />
              </View>

            </View>
                        </View>
                        {fnError === 'pn' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

                {!userInfo?.businessName ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Business Name</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='Johnnie Events' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangebusinessName} value={businessName} />
              </View>

            </View>
                        </View>
                        {fnError === 'busN' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              {!userInfo?.businessAddress ? 
                    <View>
                        <View style={{marginBottom: 10}}>
                <Text style={{fontSize: 12}}>Business Address</Text>
              

              <View style={{width: width - 30, borderWidth: 0, marginTop: 10, borderColor: 'black', flexDirection: 'row', height: 45, backgroundColor: '#ebebeb', borderRadius: 5, marginLeft: 0}}>
              
              <View style={{height: '100%', width: width - 30, justifyContent:'center', paddingLeft: 25}}>
                <TextInput placeholder='45 Commissioner Street, Johannesburg, South Africa' style={{height: '100%', width: '100%', fontSize: 12}} onChangeText={ChangebusinessAddress} value={businessAddress} />
              </View>

            </View>
                        </View>
                        {fnError === 'busAdd' ?  <Text style={{color: 'red', fontSize: 9}}>{fnErrorMessage || `Error`}</Text> : <View />} 
                    </View>  
                    : 
                    <View style={{position: 'absolute'}}/>
                }

              <Animated.View  style={{flexDirection: 'row', marginLeft: 0, marginRight: 0, marginTop: 35, justifyContent: 'space-between'}}>
                

                <TouchableOpacity onPress={()=> {{submitUpdate()}}} style={{height: 45, flexDirection: 'row', width: width - 30, borderRadius: 5, backgroundColor: '#FF4F00', alignItems: 'center', justifyContent: 'center'}}>
                  
                  { 
                    spinning ? 
                    <View style={{position: 'absolute', left: 15}}>
                        <ActivityIndicator size={'small'} color= {'#fcfcfc'} />
                    </View>
                    :
                    <View />
                  }
                  
                  <Text style={{color: 'white', fontSize: 13, fontWeight: 'bold', marginRight: 10}}>Update Details</Text>
                  <AntDesign name='arrowright' size={15} color={'white'}/>
                </TouchableOpacity>


              </Animated.View>   

              <View style={{marginBottom: height / 1.5, height: 5, width: 5, backgroundColor: 'transparent'}}/>


          </Animated.View>

          </ScrollView>

    </Animated.View> 



    </View>
    
  )
}

export default TabNav

const styles = StyleSheet.create({})