import { getStateFromPath } from "@react-navigation/native";
import React, {useRef, useState, useEffect} from "react";
import {View, Text, Image, Animated, Dimensions, TouchableOpacity} from 'react-native';
import { Entypo, Feather } from '@expo/vector-icons';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUserData } from "../../graphql/queries";

const {width, height} = Dimensions.get('screen')

const MessagesScreen = () => {


    const profileMainY = useRef(new Animated.Value(100)).current;  
    const optionsMainY = useRef(new Animated.Value(60)).current; 

    const progileImagePlace = '';

    const theblue = '#226dbf'


    const [userInfo, setUserInfo] = useState()

    const checkAuth = async()=>{
  
        const userAuthenticated =  await Auth.currentAuthenticatedUser();
        const userSub = userAuthenticated.attributes.sub;
    
    
      try{
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
            const userd = ef.data.getUserData
    
            setUserInfo(userd)
    
          }).catch((ee)=> {
            console.error(ee)
          })
    
      } catch (e){
        console.error(e)
      }
      
    
    
      }


    useEffect(() => {

        checkAuth()

        Animated.parallel([
            Animated.timing(profileMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
            Animated.timing(optionsMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, []);    




    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa', padding: 15}}>
            <Animated.View style={{alignItems: 'center', marginTop: 5, height: height / 2, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, justifyContent: 'center', transform: [{translateY: profileMainY}]}}>
                <Animated.View style={{width: 120, height: 120, borderRadius: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                <Image source={require('../../assets/confused.png')} style={{height: 120, width: 120, borderRadius: 0}} />

                </Animated.View>
                <Animated.View style={{alignItems: 'center', marginTop: 20}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>No Messages...</Text>
                    <Text style={{fontSize: 11, color: '#444e59'}}>There's nothing here.. You have no messages</Text>
                </Animated.View>


                <TouchableOpacity style={{height: 40, width: width / 3, backgroundColor: theblue, marginTop: 15, flexDirection: 'row', borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Feather name= 'message-square' color={'#fcfcfc'} size={18} />
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 5}}>Let's chat ?</Text>
                            </TouchableOpacity>


            </Animated.View>

           

        </View>
    )
}

export default MessagesScreen