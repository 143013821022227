import react, {useEffect, useState, useRef} from "react";
import {View, Text, Image, ImageBackground, Dimensions, Animated, TouchableOpacity, TextInput, ScrollView, ActivityIndicator} from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { FlashList } from "@shopify/flash-list";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { getUserData } from "../../graphql/queries";



const {width, height} = Dimensions.get('screen')

const CashOut = () => {

    const navigation = useNavigation();

    const [userInfo, setUserInfo] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [withAmount, setWithAmount] = useState('')


    const WalletMainY = useRef(new Animated.Value(100)).current;  
    const eventsMainY = useRef(new Animated.Value(60)).current;   

    const [spinning, setSpinning] = useState(false);    const theorange = '#d14324';
    const theblue = '#226dbf'

    const getUserInfo = async()=>{
  
      const userAuthenticated =  await Auth.currentAuthenticatedUser();
      const userSub = userAuthenticated.attributes.sub;
  
  
    try{
          await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
          const userd = ef.data.getUserData
  
          setUserInfo(userd)
  
        }).catch((ee)=> {
          console.error(ee)
        })
  
    } catch (e){
      console.error(e)
    }

    }


    

useEffect(() => {
  getUserInfo()

  Animated.parallel([
    Animated.timing(WalletMainY, {
        toValue: 0,
        useNativeDriver: true
    }),
    Animated.timing(eventsMainY, {
        toValue: 0,
        useNativeDriver: true
    }),
]).start()

  // if(withAmount > availBal){
  //   setErrorMsg('Insufficient Amount')
  // } else {
  //   setErrorMsg('')
  // }
  
  }, [userInfo])

  
  let availBal = (userInfo?.balance - userInfo?.balance * 0.03)


  const withdrawFunds = async()=> {

    setSpinning(true)

    if(withAmount > availBal){
      setErrorMsg('Insufficient Amount')
      setSpinning(false)
      return
    }

    if(!userInfo?.bank){
      setErrorMsg('Please Add Banking Details')
      setSpinning(false)
      return
    }  

    if(withAmount < 1){
      setErrorMsg('Enter Amount')
      setSpinning(false)
      return
    }

    if(userInfo?.withdrawnAmount > 0){
      setErrorMsg('You have a pending payout')
      setSpinning(false)
      return
    }

    const userId = await Auth.currentAuthenticatedUser()
 
    const inputUser = {
      id: userId.attributes.sub,
      balance: (userInfo?.balance || 0) - Number(withAmount),
      withdrawnAmount: (userInfo?.withdrawnAmount || 0) + Number(withAmount),

    }

    const inputTransaction = {
      userId: userId.attributes.sub,
      amount: Number(withAmount),
      inOrOut: 'Withdrawal',
    }

    try {
     await API.graphql(graphqlOperation(mutations.updateUserData, {input: inputUser})).then((e)=> {
      API.graphql(graphqlOperation(mutations.createTransactions, {input: inputTransaction})).then((eff)=> {
        navigation.goBack(),
        setSpinning(false)
      })
     })
    } catch (error) {
      console.error(error)
    }    




  }


  return (
    <View style={{flex: 1, backgroundColor: '#e3eefa'}}>
      <View style={{height: 60, width : width, backgroundColor: '#fcfcfc', flexDirection: 'row', alignItems: 'center', paddingLeft: 15}}>
                <TouchableOpacity onPress={()=> navigation.goBack()}>
                    <Ionicons name='arrow-back' size={20} />
                </TouchableOpacity>

                <Animated.View style={{marginLeft: 25}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold'}}>Cash Out</Text>
                </Animated.View>
                
        </View>

        <ScrollView>
          

        <Animated.View style={{alignItems: 'center',marginTop: 10,  backgroundColor: '#fcfcfc',  borderRadius: 10, paddingTop: 25, paddingBottom: 25, margin: 10, transform: [{translateY: WalletMainY}]}}>
                
                <View style={{position: 'absolute', height: 30, width: 30, borderRadius: 40, backgroundColor: theorange, top: 5, right: 5, alignItems: 'center', justifyContent: 'center'}}>
                   <View>
                    <Entypo name= 'wallet' size={12} color="#fcfcfc" />
                   </View>
                    
                </View>


                <Animated.View style={{flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}>
                     <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number(userInfo?.balance || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>Total Amount</Text>
                    </Animated.View>

                    <Animated.View style={{alignItems: 'center', marginTop: 0}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>ZAR{Number((availBal) || 0).toFixed(2)}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>Available Amount</Text>
                    </Animated.View>               
                </Animated.View>

                <Animated.View style={{width: '80%', height: 70, backgroundColor: 'rgba(227, 238, 250, 0.5)', flexDirection: 'row', borderRadius: 10, marginTop: 15 }}>
                    
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <TouchableOpacity 
                        onPress={()=> navigation.navigate('BankingDetails')}
                        style={{height: 60, width: 120, borderWidth: 0, justifyContent: 'center', alignItems: 'center'}}>
                                
                                <View style={{height: 28, width: 28, backgroundColor: theorange, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Feather name='list' color='#fcfcfc' size={15} />
                                </View>

                                <Text style={{fontSize: 10,  marginTop: 5}}>Banking Details</Text>

                        </TouchableOpacity>

                       

                    </View>

                    


                </Animated.View>

               

            </Animated.View>


            <Animated.View style={{alignItems: 'center', marginTop: 5, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, marginHorizontal: 10, transform: [{translateY: eventsMainY}]}}>

                <View style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'wallet' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>How much do you need ?</Text>
                    </View>
                  
                </View>

              
                        <View style={{marginTop: 20, marginBottom: 30, alignItems: 'center', width: '100%'}}>
                            <View style={{width: '80%', backgroundColor: 'rgba(227, 238, 250, 0.5)', alignItems: 'center', borderRadius: 10, paddingLeft: 15, flexDirection: 'row'}}>
                              <Text style={{fontWeight: 'bold', color: '#052242', marginRight: 10}}>R</Text>
                              <TextInput style={{height: 50, width: '100%'}} placeholder= {String(availBal)} onChangeText={setWithAmount} value={withAmount} keyboardType='number-pad' />
                            </View>

                            {errorMsg && 
                            <Text style={{fontSize: 11, color: 'red'}}>{errorMsg}</Text>
                            }

                            {withAmount > availBal ? 
                              <View style={{height: 45, width: '80%', backgroundColor: theblue, opacity: 0.65, marginTop: 15,  borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 0}}>Insufficient Funds</Text>
                              </View> :

                            <TouchableOpacity onPress={()=> withdrawFunds()} style={{height: 45, width: '80%', backgroundColor: theblue, marginTop: 15,  borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                                {spinning && 
                                  <View style={{position: 'absolute', left: 15}}>
                                      <ActivityIndicator size={'small'} color={'#fcfcfc'} />
                                  </View>
                                }
                                
                                <Text style={{color: '#fcfcfc', fontSize: 12, fontWeight: 'bold', marginLeft: 0}}>Withdraw Funds</Text>
                            </TouchableOpacity>
                          }
                            


                        </View>
                     
                    
               





            </Animated.View>

        
            </ScrollView>
    </View>
  )
}

export default CashOut

