import { View, Text, StyleSheet, Animated, TouchableOpacity, Dimensions, Image, ImageBackground, ScrollView, TextInput } from "react-native";
import React, {useState, useEffect, useRef} from 'react';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Entypo, FontAwesome5, Feather, MaterialIcons, EvilIcons, Ionicons, AntDesign , Octicons, MaterialCommunityIcons   } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from "@react-navigation/native";
import { FlashList } from "@shopify/flash-list";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { Auth, API, graphqlOperation } from "aws-amplify";

const {width, height} = Dimensions.get('screen');





const SalesMainList = ({name}) => {


    const navigation = useNavigation();
    const [campaigns, setCampaigns] = useState([])

    const mainColour = '#d40404';
    const deepBlueColour = '#052242'

    // ownerId
 
    const listingMessageRooms = async() => {
        const userplus = await Auth.currentAuthenticatedUser({bypassCache: true});
        

            const allTodos = await API.graphql({query: queries.listOrders, variables: 
                {
                filter : { and: [ 
                    { campaignId: {eq: name}},
                
                ]}
            } });
            
            const todos = allTodos.data.listOrders.items
            setCampaigns(todos)


    }

    useEffect(() => {

        listingMessageRooms();
      }, [campaigns])
   
    
      let totalPrice = 0;

    totalPrice = campaigns?.reduce((summedPrice, product) => 
    (summedPrice + (product?.orderAmount || 0) * 1 ), 
    0);


      const renderCampaigns = ({item}) => {
        return(
            <TouchableOpacity onPress={()=> {}} style={{height: 80, width: width - 30, marginBottom: 10, borderWidth: 0, borderColor: '#d1d1d1', borderRadius: 10, paddingLeft: 5, flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(227, 238, 250, 0.4)'}}>
                
                <View style={{width: '20%'}}>
                    <Image
                        style={{height: 70, width: 70, borderRadius: 10}}
                        source={{uri: item?.image}}
                    />
                </View>

                <View style={{height: '100%', marginLeft: 15, justifyContent: 'center', width: '65%'}}>
                  <Text style={{fontWeight: 'bold', fontSize: 15, color: deepBlueColour}}>{item?.title || `Title`}</Text>  
                  <Text style={{fontSize: 12, width: width / 2, textAlign: 'justify', color: 'gray'}}>ZAR{Number(item?.orderAmount).toFixed(2)}  -  {item?.seatId}</Text>
                  <Text style={{fontSize: 10, width: width / 2, textAlign: 'justify', color: 'gray'}}>{String(item?.createdAt).slice(0,10)}</Text>
                </View>

                <View style={{width: '10%', alignItems: 'center', paddingRight: 15}}>
                    <Feather name='arrow-right' size={20}/>
                </View>
                
            </TouchableOpacity>
        )
      }

      

    return(
        <View style={{flex: 1, backgroundColor: '#e8e8e8'}}>
                   
           

            <View style={{backgroundColor: '#fcfcfc', width: width, marginTop: 0, padding: 15, marginBottom: 100}}>

                {/* <View style={{flexDirection: 'row', alignItems: 'center', backgroundColor: '#f2f2f2', borderRadius: 20, height: 45, paddingLeft: 15, width: width - 90, alignSelf: 'center'}}>
                    <Feather name="search" size={20} color='gray' style={{marginRight: 25}} />
                    <TextInput placeholder="Seach for a Campaign" placeholderTextColor={'gray'} style={{fontSize: 12, height: '100%', width: '100%'}}/>
                </View> */}

                <View style={{width: '100%', height: '100%', paddingTop: 15}}>

                    <FlashList 
                        data={campaigns}
                        renderItem={renderCampaigns}
                        estimatedItemSize={3000}
                    />
                    </View>
            </View>


           
            <Animated.View style={{height: 80, width: width - 30, position: 'absolute', borderWidth: 1, borderColor: 'rgba(34, 109, 191, 0.18)', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(227, 238, 250, 0.999)', bottom: height / 3 + 10, alignSelf: 'center', borderRadius: 10}}>
                  
                    <View style={{position: 'absolute', right: 15, top: 15, backgroundColor: 'red', paddingVertical: 5,  borderRadius: 5, paddingHorizontal: 5, alignItems: 'center'}}>
                        <Text style={{color: '#fcfcfc', fontWeight: 'bold', fontSize: 10}}>{campaigns.length || `0`}</Text>
                    </View>

                  
                    <Text style={{fontSize: 11}}>Total Amount</Text>
                    <Text style={{fontWeight: 'bold', fontSize: 18, color: deepBlueColour}}>ZAR{Number(totalPrice).toFixed(2)}</Text>
            </Animated.View>




        </View>
    )

}
export default SalesMainList