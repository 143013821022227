import { getStateFromPath } from "@react-navigation/native";
import React, {useRef, useState, useEffect} from "react";
import {View, Text, Image, Animated, Dimensions, TouchableOpacity} from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUserData } from "../../graphql/queries";

const {width, height} = Dimensions.get('screen')

const MenuScreen = () => {


    const profileMainY = useRef(new Animated.Value(100)).current;  
    const optionsMainY = useRef(new Animated.Value(60)).current; 

    const progileImagePlace = ''


    const [userInfo, setUserInfo] = useState()

    const checkAuth = async()=>{
  
        const userAuthenticated =  await Auth.currentAuthenticatedUser();
        const userSub = userAuthenticated.attributes.sub;
    
    
      try{
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
            const userd = ef.data.getUserData
    
            setUserInfo(userd)
    
          }).catch((ee)=> {
            console.error(ee)
          })
    
      } catch (e){
        console.error(e)
      }
      
    
    
      }


    useEffect(() => {

        checkAuth()

        Animated.parallel([
            Animated.timing(profileMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
            Animated.timing(optionsMainY, {
                toValue: 0,
                useNativeDriver: true
            }),
        ]).start()
    
      }, []);    




    return(
        <View style={{flex: 1, backgroundColor: '#e3eefa', padding: 15}}>
            <Animated.View style={{alignItems: 'center',marginTop: 5, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, transform: [{translateY: profileMainY}]}}>
                <Animated.View style={{width: 80, height: 80, borderRadius: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                    <View style={{width: 80, height: 80, borderRadius: 70, backgroundColor: 'rgba(86, 137, 199, 0.6)', alignItems: 'center', justifyContent: 'center'}}>
                           <Image source={require('../../assets/b.png')} style={{height: 70, width: 70, borderRadius: 100}} />
                    </View>
                </Animated.View>
                <Animated.View style={{alignItems: 'center', marginTop: 20}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold', color: '#052242'}}>{userInfo?.firstName} {userInfo?.surname}</Text>
                    <Text style={{fontSize: 12, color: '#444e59'}}>{userInfo?.email}</Text>
                </Animated.View>

                <View style={{position: 'absolute', height: 30, width: 30, borderRadius: 40, backgroundColor: '#d14324', top: 80, alignItems: 'center', justifyContent: 'center'}}>
                   <TouchableOpacity onPress={()=> console.warn('ss')}>
                    <Entypo name="edit" size={12} color="#fcfcfc" />
                   </TouchableOpacity>
                    
                </View>

            </Animated.View>

            <Animated.View style={{alignItems: 'center',marginTop: 15, backgroundColor: '#fcfcfc', borderRadius: 10, paddingTop: 15, paddingBottom: 15, transform: [{translateY: optionsMainY}]}}>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'quote' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>FAQs</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'notification' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Notifications</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'attachment' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Documentation</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'document-landscape' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Terms & Conditions</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'document-landscape' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Privacy Policy</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>

                <TouchableOpacity style={{height: 50, width: '90%', justifyContent: 'space-between', borderBottomWidth: 0, borderColor: '#d1d1d1', flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 50}}>
                            <Entypo name= 'document-landscape' size={20} color={'#052242'} />
                        </View>

                        <Text style={{fontWeight: 'bold', fontSize: 15, color:'#052242'}}>Refund Policy</Text>
                    </View>

                    <View>
                        <Entypo name= 'arrow-long-right' />
                    </View>
                </TouchableOpacity>


            </Animated.View>

        </View>
    )
}

export default MenuScreen